import { ComponentProps } from 'react';
import { Button } from '@konta/ui';
import { ArrowNarrowLeftLineIcon } from '@konta/icons';
import { ContainerBackButton } from './styled';

export interface BackButtonProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  children?: string;
}
export default function BackButton(buttonProps: BackButtonProps) {
  return (
    <ContainerBackButton>
      <Button
        data-cy="resumeStepBackBtn"
        leftIcon={<ArrowNarrowLeftLineIcon />}
        variant="outlined"
        {...buttonProps}
      >
        Regresar
      </Button>
    </ContainerBackButton>
  );
}
