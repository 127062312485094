import { Navigate, Route, Routes } from 'react-router-dom';
import BLOG_POSTS from '@constants/blogpostUrl';
import Post from './Post';

export default function Blog() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={`/blog/${BLOG_POSTS[0].slug}`} replace />}
      />
      {BLOG_POSTS.map((post) => (
        <Route
          key={post.id}
          path={`/${post.slug}`}
          element={<Post url={post.url} />}
        />
      ))}
    </Routes>
  );
}
