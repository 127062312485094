import { FISCAL_VISOR } from '@constants/featureFlags';
import useFeature from '@hooks/useFeature';
import { subscriptionPrices } from '@redux/subscriptions/actions';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'rooks';
import type { IRootState } from 'types/redux';
import useTaxableEntityShared from 'shared/hooks/useSharedTaxableEntity';
import dayjs from 'dayjs';

const SYNC_MESSAGE_DURATION = 86400000; // 24 hours in milliseconds
export default function useInformationDashboard() {
  const { taxableEntity } = useTaxableEntityShared();
  const [isFiscalVisorEnabled] = useFeature(FISCAL_VISOR);
  const [showSyncMessage, setShowSyncMessage] = useState(true);

  const subscription = useSelector(
    (state: IRootState) => state.subscriptions.subscriptions[0],
    shallowEqual,
  );
  const dateSubscription = subscription?.date_subscribed as string;

  const dispatch = useDispatch();

  useEffect(() => {
    const subscriptionTime = dayjs(dateSubscription);
    const currentTime = dayjs();
    const elapsedTime = currentTime.diff(subscriptionTime);

    if (elapsedTime >= SYNC_MESSAGE_DURATION) {
      setShowSyncMessage(false);
      return undefined;
    }
    const timer = setTimeout(() => {
      setShowSyncMessage(false);
    }, SYNC_MESSAGE_DURATION - elapsedTime);

    return () => clearTimeout(timer);
  }, [dateSubscription]);

  const hidePrices = useCallback(() => {
    dispatch(subscriptionPrices(true));
  }, [dispatch]);

  const hasNotActiveSubscription = taxableEntity?.status === 'demo';

  const isCIECInvalid =
    taxableEntity?.ciec_status?.status === 'invalid' ||
    taxableEntity?.ciec_status?.status === 'error';

  const shouldOpenFiscalVisorModalCheckout = useMemo(
    () => isFiscalVisorEnabled && (hasNotActiveSubscription || isCIECInvalid),
    [isFiscalVisorEnabled, hasNotActiveSubscription, isCIECInvalid],
  );

  const [openFiscalVisorModalCheckout, toggleFisalVisorModalCheckout] =
    useToggle(shouldOpenFiscalVisorModalCheckout);

  const handleModalClose = useCallback(() => {
    toggleFisalVisorModalCheckout();
    hidePrices();
  }, [toggleFisalVisorModalCheckout, hidePrices]);

  return useMemo(() => {
    const taxableEntityRegimes = taxableEntity?.fiscal_regimes;

    const currentActiveRegimesWihtouthResico = taxableEntityRegimes?.filter(
      (regime) => regime.sat_key !== 626,
    );
    const isActiveRegimes = currentActiveRegimesWihtouthResico?.some(
      (regime) => !regime.end_date,
    );

    const isResico =
      taxableEntityRegimes?.length === 1 &&
      taxableEntityRegimes[0].sat_key === 626;

    return {
      isResico,
      taxableEntity,
      hasNotActiveSubscription,
      toggleFiscalVisorModalCheckout: handleModalClose,
      shouldOpenFiscalVisorModalCheckout,
      openFiscalVisorModalCheckout,
      isFiscalVisorEnabled,
      showSyncMessage,
      isActiveRegimes,
    };
  }, [
    taxableEntity,
    hasNotActiveSubscription,
    shouldOpenFiscalVisorModalCheckout,
    handleModalClose,
    openFiscalVisorModalCheckout,
    isFiscalVisorEnabled,
    showSyncMessage,
  ]);
}
