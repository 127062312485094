const BLOG_POSTS = [
  {
    id: 1,
    url: 'https://raw.githubusercontent.com/buhocontable/blog-posts/refs/heads/main/gu%C3%ADa-completa-de-las-deducciones-fiscales-en-m%C3%A9xico.md',
    slug: 'guia-completa-de-las-deducciones-fiscales-en-mexico',
  },
  // TODO: add more blog posts
];

export default BLOG_POSTS;
