import { useCallback } from 'react';
import { TextStep } from 'types/flows';
import getIconFromString from 'shared/util/getIconFromString';
import { IconContainer, Subtitle, Title } from './styled';

interface TextStepContentProps {
  step: TextStep;
}

export default function TextStepContent({ step }: TextStepContentProps) {
  const { params } = step;
  const { title, emoji, body } = params;
  const renderIcon = useCallback(() => getIconFromString(emoji), [emoji]);

  return (
    <>
      <IconContainer>{renderIcon()}</IconContainer>
      <Title>{title}</Title>
      <Subtitle>{body}</Subtitle>
    </>
  );
}
