import { useMemo } from 'react';
import useClientSuppliers from 'shared/hooks/useClientSuppliers';
import useSharedTaxableEntity from 'shared/hooks/useSharedTaxableEntity';
import ClientSupplierList from '../ClientSupplierList';

export default function ClientsPage() {
  const { taxableEntityId } = useSharedTaxableEntity();
  const { clientSuppliers, clientSuppliersLoading } = useClientSuppliers(
    taxableEntityId,
    undefined,
    {
      staleTime: 60 * 1000,
    },
  );
  const clients = useMemo(
    () =>
      clientSuppliers.filter(
        (clientSupplier) => clientSupplier.relationship_type === 'client',
      ),
    [clientSuppliers],
  );

  return (
    <ClientSupplierList
      clientSuppliersLoading={clientSuppliersLoading}
      clientSuppliers={clients}
      isClient
    />
  );
}
