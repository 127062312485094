import { Flex } from '@konta/ui';
import { TaxableEntity } from 'types/entities';
import EntriesTable from './EntriesTable';
import useEntries from './useEntries';

interface AnnualDeclarationEntriesProps {
  workflowsId: number[];
  taxableEntity: TaxableEntity | null;
  filters: Record<string, string>;
  period: string | number;
}
export default function AnnualDeclarationEntries({
  workflowsId,
  taxableEntity,
  filters,
  period,
}: AnnualDeclarationEntriesProps) {
  const { declarationEntries, declarationEntriesLoading } = useEntries({
    workflowsId,
    taxableEntityId: taxableEntity?.id || 0,
    filters,
    period,
  });

  return (
    <Flex column css={{ flex: 1, width: '100%' }}>
      <EntriesTable
        declarationEntries={declarationEntries}
        declarationEntriesLoading={declarationEntriesLoading}
        accessToken={taxableEntity?.user?.access_token || ''}
      />
    </Flex>
  );
}
