import { useState } from 'react';
import { Alert, Button, Flex } from '@konta/ui';
import { UserCircleLineIcon } from '@konta/icons';
import useFetchPostContent from 'shared/hooks/useFetchPostContent';
import Loader from 'shared/components/Loader';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as VerifiedBadge } from './verified-badge.svg';
import { BlogContainer, BlogContent, BlogFooter, BlogHeader } from './styled';

interface BlogPostProps {
  url: string;
  slug: string;
}

export default function BlogPost({ url, slug }: BlogPostProps) {
  const [showDefaultAvatar, setShowDefaultAvatar] = useState(false);
  const { postContent, loadingPostContent, isError } = useFetchPostContent(url);
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    navigate(`/blog/${slug || ''}`);
  };

  if (loadingPostContent) {
    return <Loader />;
  }

  if (!postContent || isError) {
    return (
      <Flex column>
        <Alert color="error">Error al cargar el post del blog</Alert>
      </Flex>
    );
  }

  return (
    <BlogContainer onClick={handleOpenDialog}>
      <BlogHeader>
        <div data-slot="image-container">
          {showDefaultAvatar && <UserCircleLineIcon />}
          <img
            onError={() => setShowDefaultAvatar(true)}
            src={postContent.metadata?.img}
            alt="Avatar"
            data-error={showDefaultAvatar}
          />
          <VerifiedBadge />
        </div>
        <div data-slot="title">
          <span data-slot="author">{postContent.metadata?.author}</span>
          <span data-slot="description">{postContent.metadata?.bio}</span>
        </div>
      </BlogHeader>
      <BlogContent>
        <span data-slot="title">{postContent.metadata?.title}</span>
        <span data-slot="subtitle">{postContent.metadata?.summary}</span>
      </BlogContent>
      <BlogFooter>
        <Button color="primary600" onClick={handleOpenDialog}>
          Leer más
        </Button>
      </BlogFooter>
    </BlogContainer>
  );
}
