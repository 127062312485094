import { Command } from 'cmdk';
import Loader from 'shared/components/Loader';
import type { ClientSupplier } from 'types/entities';
import { useCommandMenuContext } from '@providers/CommandMenuProvider';
import ClientSupplierItem from './ClientSupplierItem';

interface ClientSupplierListProps {
  clientSuppliersLoading: boolean;
  clientSuppliers: ClientSupplier[];
  isClient: boolean;
}

export default function ClientSupplierList({
  clientSuppliersLoading,
  clientSuppliers,
  isClient,
}: ClientSupplierListProps) {
  const { navigateTo } = useCommandMenuContext();
  return (
    <>
      {clientSuppliersLoading && (
        <Command.Loading>
          <Loader />
        </Command.Loading>
      )}
      {!clientSuppliersLoading &&
        clientSuppliers.map((clientSupplier) => {
          const { id, rfc, legal_name } = clientSupplier;
          return (
            <ClientSupplierItem
              onSelect={() => {
                if (!rfc) return;
                navigateTo('/app/invoicing/contacts', {
                  replace: true,
                  state: {
                    selectedTab: isClient ? 1 : 2,
                    selectedItem: {
                      rfc,
                      legalName: legal_name || '-',
                      isEmitted: !!isClient,
                    },
                  },
                });
              }}
              clientSupplier={clientSupplier}
              key={`client-supplier-${id}`}
            />
          );
        })}
    </>
  );
}
