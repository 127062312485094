import { Flex, Text } from '@konta/ui';

export default function Header() {
  return (
    <Flex
      direction="column"
      css={{
        mb: '$24',
      }}
    >
      <Flex
        direction="column"
        css={{ background: '#fff', width: '100%' }}
        gap={12}
      >
        <Text l color="gray700">
          Con base en tu contabilidad, aprende y mejora tus finanzas con
          nuestros blogs.
        </Text>
      </Flex>
    </Flex>
  );
}
