import { useMemo, useState } from 'react';
import CfdisTotalsModal from 'shared/components/CfdisTotalsModal';
import useAccumulatedIncomesAndExpenses from 'shared/hooks/useAccumulatedIncomesAndExpenses';
import { AccumulatedIncomeAndExpenseFilters } from 'types/entities';

interface InvoiceTransactionGraphProps {
  open: boolean;
  toggleOpen: () => void;
  selectedData: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  };
  isModal?: boolean;
}

export default function InvoiceTransactionGraph({
  open,
  toggleOpen,
  selectedData,
  isModal = true,
}: InvoiceTransactionGraphProps) {
  const [filters, setFilters] = useState<Record<string, string>>({
    type: 'accumulated',
    period: 'currentYear',
  });

  const { isEmitted } = selectedData;

  const clientRfcParam = isEmitted ? 'receiver_rfc_eq' : 'issuer_rfc_eq';

  const { incomesDataSet, isLoading, expensesDataSet } =
    useAccumulatedIncomesAndExpenses({
      filter: filters.period as AccumulatedIncomeAndExpenseFilters,
      params: {
        accumulate_periods: filters.type === 'accumulated',
        [`q[${clientRfcParam}]`]: selectedData.rfc,
      },
    });

  const seriesGraph = useMemo<'area' | 'line' | 'none'>(() => {
    if (filters.type === 'accumulated') {
      return 'line';
    }
    return 'none';
  }, [filters.type]);

  const dataSet = isEmitted ? incomesDataSet : expensesDataSet;

  const title = isEmitted
    ? `Ingresos facturados de ${selectedData.legalName}`
    : `Egresos facturados de ${selectedData.legalName}`;

  return (
    <CfdisTotalsModal
      isModal={isModal}
      open={open}
      toggleOpen={toggleOpen}
      title={title}
      id="test1"
      dataSet={dataSet}
      filters={filters}
      setFilters={setFilters}
      series={seriesGraph}
      invoiceType={isEmitted ? 'incomes' : 'expenses'}
      selectedData={selectedData}
      datasetLoading={isLoading}
    />
  );
}
