import { Flex } from '@konta/ui';
import BLOG_POSTS from '@constants/blogpostUrl';
import BlogPost from '../BlogPost/BlogPost';

export default function BlogList() {
  // instead a fex, should be a div with a grid of 2 columns
  return (
    <Flex
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '$16',
      }}
    >
      {BLOG_POSTS.map(({ url, slug }) => (
        <BlogPost key={slug} url={url} slug={slug} />
      ))}
    </Flex>
  );
}
