import { useState } from 'react';
import { UserCircleLineIcon } from '@konta/icons';
import { Alert, Button, Flex, useDialog } from '@konta/ui';
import Loader from 'shared/components/Loader';
import PostDialog from 'shared/components/PostDialog';
import { BlogStep } from 'types/flows';
import useFetchPostContent from 'shared/hooks/useFetchPostContent';
import { ReactComponent as VerifiedBadge } from './verified-badge.svg';
import { BlogContainer, BlogHeader, BlogContent, BlogFooter } from './styled';

interface BlogStepContentProps {
  step: BlogStep;
  setIsActiveDialog?: (isActiveDialog: boolean) => void;
  setIsOpen?: (isOpen: boolean) => void;
}

export default function BlogStepContent({
  step,
  setIsActiveDialog,
  setIsOpen: setIsInsightsDialogOpen,
}: BlogStepContentProps) {
  const { params } = step;
  const { url } = params;
  const { isOpen, setIsOpen } = useDialog();
  const [showDefaultAvatar, setShowDefaultAvatar] = useState(false);
  const { postContent, loadingPostContent, isError } = useFetchPostContent(url);

  const handleOpenDialog = () => {
    setIsActiveDialog?.(false);
    setIsOpen(true);
  };

  const handleToggleDialog = (newOpen: boolean) => {
    setIsActiveDialog?.(!newOpen);
    setIsOpen(newOpen);
  };

  if (loadingPostContent) {
    return <Loader />;
  }

  if (!postContent || isError) {
    return (
      <Flex column>
        <Alert color="error">Error al cargar el post del blog</Alert>
      </Flex>
    );
  }

  return (
    <BlogContainer>
      <PostDialog
        isOpen={isOpen}
        setIsOpen={handleToggleDialog}
        blogContent={postContent}
      />
      <BlogHeader>
        <div data-slot="image-container">
          {showDefaultAvatar && <UserCircleLineIcon />}
          <img
            onError={() => setShowDefaultAvatar(true)}
            src={postContent.metadata?.img}
            alt="Avatar"
            data-error={showDefaultAvatar}
          />
          <VerifiedBadge />
        </div>
        <div data-slot="title">
          <span data-slot="author">{postContent.metadata?.author}</span>
          <span data-slot="description">{postContent.metadata?.bio}</span>
        </div>
      </BlogHeader>
      <BlogContent>
        <span data-slot="title">{postContent.metadata?.title}</span>
        <span data-slot="subtitle">{postContent.metadata?.summary}</span>
      </BlogContent>
      <BlogFooter>
        <Button color="primary600" onClick={handleOpenDialog}>
          Ver más
        </Button>
        {/* <Button
          variant="text"
          color="primary600"
          onClick={() => {
            setIsInsightsDialogOpen?.(false);
          }}
        >
          Recordar más adelante
        </Button> */}
      </BlogFooter>
    </BlogContainer>
  );
}
