import { TaxableEntity } from 'types/entities';
import AnnualDeclarationEntries from 'shared/components/AnnualDeclarationEntries';
import { Root } from './styled';

interface EntriesProps {
  workflowsId: number[];
  taxableEntity: TaxableEntity | null;
  filters: Record<string, string>;
  period: string | number;
}
export default function Entries({
  workflowsId,
  taxableEntity,
  filters,
  period,
}: EntriesProps) {
  return (
    <Root>
      <AnnualDeclarationEntries
        workflowsId={workflowsId}
        taxableEntity={taxableEntity}
        filters={filters}
        period={period}
      />
    </Root>
  );
}
