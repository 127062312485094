import { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import usePendingTasksStore from 'store/pendingTasksStore';
import useHandleDebsOrders from './useHanleDebsOrders';
import useHandleWorkflows from './useHandleWorkflows';
import useHandleProcess from './useHandleProcess';
import useHandlePreOnboarding from './useHandlePreOnboarding';
import { TaskStatus } from '../types';
import useAnnualDeclarationPreview from './useAnnualDeclarationPreview';

export default function usePendingTask() {
  const { pendingTasks, cleanPendingTask, cleanSelectedTask } =
    usePendingTasksStore(
      useShallow((state) => ({
        pendingTasks: state.pendingTasks,
        cleanPendingTask: state.cleanPendingTask,
        cleanSelectedTask: state.cleanSelectedTask,
      })),
    );

  useEffect(
    () => () => {
      cleanPendingTask();
      cleanSelectedTask();
    },
    [cleanPendingTask, cleanSelectedTask],
  );

  const {
    loadingOrders,
    showDebsOrderModalTask,
    toggleShowDebsOrderModalTask,
  } = useHandleDebsOrders();

  const { loadingCurrentProcesses } = useHandleProcess();

  const { showPreOnboardingModalTask, toggleShowPreOnboardingModalTask } =
    useHandlePreOnboarding();

  const { workflowsLoading } = useHandleWorkflows();
  const { loadingAnnualDeclarations } = useAnnualDeclarationPreview();

  const filteredPendingTasks = useMemo(() => {
    const filterByStatus = (status: TaskStatus) =>
      pendingTasks.filter((task) => task.status === status);

    return {
      requiredAction: filterByStatus('requiredAction'),
      pending: filterByStatus('pending'),
      completed: filterByStatus('completed'),
    };
  }, [pendingTasks]);

  const tasksLoading = useMemo(
    () =>
      loadingOrders ||
      loadingCurrentProcesses ||
      workflowsLoading ||
      loadingAnnualDeclarations,
    [
      loadingOrders,
      loadingCurrentProcesses,
      workflowsLoading,
      loadingAnnualDeclarations,
    ],
  );

  return {
    showPreOnboardingModalTask,
    toggleShowPreOnboardingModalTask,
    toggleShowDebsOrderModalTask,
    showDebsOrderModalTask,
    filteredPendingTasks,
    tasksLoading,
  };
}
