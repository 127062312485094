import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Button,
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import Avatar from 'react-avatar-edit';
import LetterAvatar from 'react-avatar';
import { Colxx } from '@components/CustomBootstrap';
import { updateTaxableEntity } from '@redux/actions';
import { convertNullToEmpty } from '@util/Forms';
import { dataURItoBlob } from '@util/Utils';
import { SimpleStateButton, Img } from '@components';
import { NotificationManager } from '@components/Notifications';

class ProfileFormCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      preview: '',
      modalOpen: false,
      error: false,
    };
  }

  uploadImage = () => {
    const { taxable_entity } = this.props.taxableEntity;
    const formData = new FormData();
    formData.append('taxable_entity[logo]', this.state.file);
    if (this.state.file) {
      this.props.updateTaxableEntity(formData, taxable_entity.id);
      this.setState({
        ...this.state,
        file: null,
        error: false,
      });
      this.toggleModal();
    } else {
      this.setState({
        ...this.state,
        error: true,
      });
      NotificationManager.error(
        'Agrega una foto para poder guardar los cambios',
        'Agrega una imagen',
        6000,
        null,
        null,
      );
    }
  };

  onFileLoad = (file) => {
    this.setState({ file });
  };

  onCrop = (preview) => {
    this.setState({
      file: dataURItoBlob(preview),
    });
    this.setState({ preview });
  };

  onClose = () => {
    this.setState({
      ...this.state,
      file: null,
    });
  };

  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  };

  handleSubmit = (event, errors, values) => {
    const { taxable_entity } = this.props.taxableEntity;

    if (errors.length === 0) {
      const body = {
        taxable_entity: {
          ...values,
        },
      };
      this.props.updateTaxableEntity(body, taxable_entity.id);
    }
  };

  render() {
    const { taxable_entity, loading } = this.props.taxableEntity;
    const avatar_name = taxable_entity?.legal_name
      ? taxable_entity.legal_name
      : this.props.user?.first_name;
    return (
      <Card>
        <CardBody>
          <CardTitle tag="h4">Perfil</CardTitle>
          <Row>
            <Colxx
              xxs={12}
              md={3}
              className="d-flex flex-column align-items-center mb-4"
            >
              {taxable_entity?.logo_url ? (
                <div className="w-70 w-sm-20 profile-logo-container mb-4">
                  <Img
                    roundedCircle
                    className="profile-logo"
                    src={taxable_entity?.logo_url}
                  />
                </div>
              ) : (
                <LetterAvatar
                  className="mb-4"
                  maxInitials={2}
                  name={avatar_name}
                  size="170"
                  round
                />
              )}
              <SimpleStateButton
                color="primary"
                size="sm"
                loading={loading}
                onClick={() => this.setState({ modalOpen: true })}
                className="mt-1 align-self-center"
                outline
              >
                Editar
                <i className="simple-icon-pencil ml-1" />
              </SimpleStateButton>
              <Modal
                size="lg"
                isOpen={this.state.modalOpen}
                toggle={this.toggleModal}
              >
                <ModalHeader toggle={this.toggleModal}>
                  Editar logo de la empresa
                </ModalHeader>
                <ModalBody className="d-flex align-items-center align-content-center justify-content-center">
                  <Avatar
                    data-test="profile-image-imput"
                    width={450}
                    height={295}
                    cropRadius={50}
                    onCrop={this.onCrop}
                    onClose={this.onClose}
                    onFileLoad={this.onFileLoad}
                    src=""
                    label="Da clic para subir una imagen"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" size="sm" onClick={this.uploadImage}>
                    Guardar
                  </Button>
                </ModalFooter>
              </Modal>
            </Colxx>
            <Colxx xxs={12} md={9} className="mb-4">
              <AvForm
                onSubmit={this.handleSubmit}
                model={convertNullToEmpty(taxable_entity)}
                className="has-float-label"
              >
                <Row>
                  <Colxx lg={5}>
                    <AvGroup>
                      <Label htmlFor="legal_first_name">
                        Nombre de contacto
                      </Label>
                      <AvInput name="legal_first_name" />
                      <AvFeedback>Nombre invalido!</AvFeedback>
                    </AvGroup>
                  </Colxx>
                  <Colxx lg={7}>
                    <AvGroup>
                      <Label htmlFor="legal_last_name">
                        Apellidos de contacto
                      </Label>
                      <AvInput name="legal_last_name" />
                      <AvFeedback>Apellido invalido!</AvFeedback>
                    </AvGroup>
                  </Colxx>
                  <Colxx lg={12}>
                    <AvGroup>
                      <Label htmlFor="curp">CURP del representante legal</Label>
                      <AvInput name="curp" />
                      <AvFeedback>CURP invalido!</AvFeedback>
                    </AvGroup>
                  </Colxx>
                  <Colxx lg={12}>
                    <AvGroup>
                      <Label htmlFor="description">
                        Breve descripción de la empresa
                      </Label>
                      <AvInput
                        className="resize: none;"
                        name="description"
                        rows="5"
                        type="textarea"
                      />
                    </AvGroup>
                  </Colxx>
                  <Colxx lg={12}>
                    <AvGroup check>
                      <AvInput type="checkbox" name="show_logo_in_invoice" />
                      <Label check for="show_logo_in_invoice">
                        Habilitar logo en la factura
                      </Label>
                    </AvGroup>
                  </Colxx>
                </Row>
                <Colxx
                  xxs="12"
                  className="d-flex align-content-center justify-content-center"
                >
                  <SimpleStateButton loading={loading}>
                    Guardar
                  </SimpleStateButton>
                </Colxx>
              </AvForm>
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ taxableEntity, authUser }) => {
  const { user } = authUser;

  return { taxableEntity, user };
};

export default connect(mapStateToProps, { updateTaxableEntity })(
  ProfileFormCard,
);
