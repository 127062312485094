import { ReactNode } from 'react';
import { Flex } from '@konta/ui';
import Breadcrumbs from '@components/Breadcrumbs';
import BackButton, { BackButtonProps } from './BackButton';
import { BreadcrumbsContainer } from './styled';

interface NavigationWrapperProps {
  children: ReactNode;
  buttonProps?: BackButtonProps;
  onClickBackBtn: BackButtonProps['onClick'];
  breadcrumbs: {
    label: string;
    onClick: () => void;
    isActive?: boolean;
  }[];
}
export default function NavigationWrapper({
  children,
  onClickBackBtn,
  buttonProps,
  breadcrumbs,
}: NavigationWrapperProps) {
  return (
    <Flex column css={{ width: '100%' }}>
      <BreadcrumbsContainer>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </BreadcrumbsContainer>
      <Flex css={{ pb: '80px' }}>{children}</Flex>
      <BackButton {...buttonProps} onClick={onClickBackBtn} />
    </Flex>
  );
}
