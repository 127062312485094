import { Command, useCommandState } from 'cmdk';

export default function EmptyState() {
  const search = useCommandState((state) => state.search) as string;

  return (
    <Command.Empty className="cmdk-empty">
      “{search}” no coincide con ningúna búsqueda.
    </Command.Empty>
  );
}
