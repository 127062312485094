import { Command } from 'cmdk';
import { SearchLgLineIcon, UserPlus01LineIcon } from '@konta/icons';
import { useCommandMenuContext } from '@providers/CommandMenuProvider';
import Item from '../Item';
import useHomePage from './useHomePage';
import ClientsPage from '../ClientsPage';
import SuppliersPage from '../SuppliersPage';
import DeclarationsPage from '../DeclarationsPage';

export default function HomePage() {
  const { navigateTo, pushPage } = useCommandMenuContext();
  const { isEnabledQueries } = useHomePage();
  const navigateToCreateClientSupplier = () => {
    navigateTo(`/app/invoicing/contacts?action=new`);
  };

  return (
    <>
      <Command.Group heading="Clientes">
        {/* <Command.Separator /> */}
        <Item shortcut="⇧ C" onSelect={() => pushPage('clients')}>
          <SearchLgLineIcon />
          Buscar clientes
        </Item>
        <Item onSelect={navigateToCreateClientSupplier}>
          <UserPlus01LineIcon />
          Crear nuevo cliente
        </Item>
        {isEnabledQueries && <ClientsPage />}
      </Command.Group>
      <Command.Group heading="Proveedores">
        <Item shortcut="⇧ P" onSelect={() => pushPage('suppliers')}>
          <SearchLgLineIcon />
          Buscar proveedores
        </Item>
        {isEnabledQueries && <SuppliersPage />}
      </Command.Group>
      {/* <Command.Group heading="Cuentas contables">
        <AccountingAccountsPage />
      </Command.Group> */}
      <Command.Group heading="Declaraciones">
        <Item onSelect={() => pushPage('declarations')}>
          <SearchLgLineIcon />
          Buscar declaraciones
        </Item>
        {isEnabledQueries && <DeclarationsPage />}
      </Command.Group>
      {/* <Command.Group heading="Ayuda">
        <Item onSelect={() => pushPage('helpCenter')}>
          <HelpCircleLineIcon />
          Centro de ayuda
        </Item>
      </Command.Group> */}
    </>
  );
}
