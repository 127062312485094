import { memo, ReactNode } from 'react';
import { Flex, Text, theme } from '@konta/ui';
import { AlertCircleLineIcon, CheckCircleLineIcon } from '@konta/icons';
import Icon from 'shared/components/Icon';

const legends = [
  {
    value: 'Completadas',
    icon: <CheckCircleLineIcon color={theme.colors.green500.value} />,
  },
  {
    value: 'Meses pendientes',
    icon: <AlertCircleLineIcon color={theme.colors.warning600.value} />,
  },
];

const styles = {
  legendContainer: {
    padding: '6px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
  },
  legendsContainer: {
    padding: '$16 0 $4 0',
  },
  legendIcon: {
    cursor: 'default',
  },
};

interface LegendProps {
  value: string;
  icon: ReactNode;
}

function Legend({ value, icon }: LegendProps) {
  return (
    <Flex css={styles.legendContainer} role="listitem">
      <Icon css={styles.legendIcon} aria-hidden="true">
        {icon}
      </Icon>
      <Text size="xs" color="gray500">
        {value}
      </Text>
    </Flex>
  );
}

function Legends() {
  return (
    <Flex gap={6} css={styles.legendsContainer} justify="between">
      <Flex gap={6} aria-label="Leyendas de estado" role="list">
        {legends.map((legend) => (
          <Legend key={legend.value} {...legend} />
        ))}
      </Flex>
    </Flex>
  );
}

export default memo(Legends);
