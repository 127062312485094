import { FeaturedSearchIcon } from '@konta/icons';
import { Flex, Icon, Text } from '@konta/ui';
import { AnnualDeclaration } from 'types/entities';
import PeriodSelector from './PeriodSelector';

interface EmptyStateProps {
  period: number;
  annualDeclarations: AnnualDeclaration[];
  setPeriod: (period: number) => void;
}

export default function EmptyState({
  period,
  annualDeclarations,
  setPeriod,
}: EmptyStateProps) {
  return (
    <Flex
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        alignSelf: 'stretch',
        height: '80%',
        justifyContent: 'center',
      }}
    >
      <Icon
        css={{
          width: '48px',
          height: '48px',
          svg: {
            width: 'auto',
            height: 'auto',
            path: {
              fill: 'none',
            },
          },
        }}
      >
        <FeaturedSearchIcon />
      </Icon>
      <Flex column gap={8} align="center" justify="center">
        <Text bold l>
          No encontramos la declaración anual
        </Text>
        <Text color="gray500">
          La declaración anual no ha sido creada para el periodo {period}
        </Text>
        {annualDeclarations.length > 0 && (
          <PeriodSelector
            annualDeclarations={annualDeclarations}
            setPeriod={setPeriod}
          />
        )}
      </Flex>
    </Flex>
  );
}
