import { shallowEqual, useSelector } from 'react-redux';
import useTaxableEntityShared from 'shared/hooks/useSharedTaxableEntity';
import type { IRootState } from 'types/redux';

type SectionKey =
  | 'academy'
  | 'reports'
  | 'invoicing'
  | 'declarations'
  | 'documents';

export default function useCanAccesToSection(sectionKey: SectionKey): boolean {
  const { taxableEntity } = useTaxableEntityShared();
  const subscription = useSelector(
    (state: IRootState) => state.subscriptions.subscriptions[0],
    shallowEqual,
  );

  const hasSubscription = subscription;
  const taxableEntityRegimes = taxableEntity?.fiscal_regimes;

  const currentActiveRegimesWihtouthResico = taxableEntityRegimes?.filter(
    (regime) => regime.sat_key !== 626,
  );
  const isActiveRegimes = currentActiveRegimesWihtouthResico?.some(
    (regime) => !regime.end_date,
  );

  const isResico =
    taxableEntityRegimes?.length === 1 &&
    taxableEntityRegimes[0].sat_key === 626;

  const sectionRules = {
    academy: hasSubscription && isActiveRegimes && !isResico,
    reports: true,
    invoicing: true,
    declarations: true,
    documents: true,
  };

  return sectionRules[sectionKey] ?? false;
}
