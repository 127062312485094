/* Gogo Textos de Lenguaje

Tabla de Contenido

01.General
02.Inicio de sesión de usuario, cierre de sesión, registro
03.Menú
04.Tableros
05.Esquemas
06.Aplicaciones
  06.01.Chatea
  06.02.Encuesta
  06.03.Notas
07.IU
  07.01.Alertas
  07.02.Badges
  07.03.Botones
  07.04.Tarjetas
  07.05.Carrusel
  07.06.Gráficos
  07.07.Colapso
  07.08.Desplegables
  07.09.Editores
  07.10.Formularios
  07.11.Componentes
  07.12.Iconos
  07.13.Grupos de Entrada
  07.14.Jumbotron
  07.15.Modal
  07.16.Navegación
  07.17.Tooltips y Popovers
  07.18.Ordenable
  07.19.Maps
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Konta',

  /* 02.Inicio de sesión de usuario, cierre de sesión, registro, settings */
  'user.login-title': 'Iniciar sesión',
  'user.register': 'Registro',
  'user.forgot-password': '¿Se te olvidó tu contraseña?',
  'user.email': 'Email',
  'user.phone-number': 'Teléfono',
  'user.password': 'Contraseña',
  'user.password-confirm': 'Confirmar contraseña',
  'user.forgot-password-question': '¿Contraseña olvidada?',
  'user.fullname': 'Nombre completo',
  'user.login-button': 'INICIAR SESIÓN',
  'user.register-button': 'REGÍSTRATE',
  'user.change-password': 'Cambiar contraseña',
  'user.reset-password-button': 'Enviar correo',
  'user.buy': 'COMPRAR',
  'user.username': 'Nombre de Usuario',

  /* 03.Menú */
  'menu.app': 'Inicio',
  'menu.invoicing': 'Facturación',
  'menu.newinvoice': 'Nueva Factura',
  'menu.information-dashboard': 'Visor Fiscal',
  'menu.new-reports': 'Reportes',
  'menu.legacy-invoice': 'Nueva Factura',
  'menu.pendientes': 'Pendientes',
  'menu.academy': 'Konta Academy',
  'menu.declaraciones': 'Declaraciones',
  'menu.declaraciones.anuales': ' Declaraciones Anuales',
  'menu.documents': 'Documentos',
  'menu.advisory-channels': 'Asesoría',
  'menu.settings': 'Configuración',
  'menu.reports': 'Historial',
  'menu.flow': 'Declaración',
  'menu.referrals': 'Referidos',
  'menu.onboarding': 'Onboarding',
  'menu.dashboards': 'Tableros',
  'menu.default': 'Defecto',
  'menu.analytics': 'Analítica',
  'menu.ecommerce': 'Comercio electrónico',
  'menu.content': 'Contenido',
  'menu.pages': 'Páginas',
  'menu.data-list': 'Lista Datos',
  'menu.thumb-list': 'Lista Pulgares',
  'menu.image-list': 'Lista Imagen',
  'menu.details': 'Detalles',
  'menu.search': 'Búsqueda',
  'menu.login': 'Iniciar sesión',
  'menu.register': 'Registro',
  'menu.forgot-password': 'Olvidé mi contraseña',
  'menu.error': 'Error',
  'menu.applications': 'Aplicaciones',
  'menu.todo': 'Notas',
  'menu.survey': 'Encuesta',
  'menu.chat': 'Chatea',
  'menu.ui': 'IU',
  'menu.alerts': 'Alertas',
  'menu.badges': 'Badges',
  'menu.buttons': 'Botones',
  'menu.cards': 'Tarjetas',
  'menu.carousel': 'Carrusel',
  'menu.charts': 'Gráficos',
  'menu.collapse': 'Colapso',
  'menu.dropdowns': 'Menús Desplegables',
  'menu.editors': 'Editores',
  'menu.forms': 'Diseños de Formulario',
  'menu.form-components': 'Componentes de Formulario',
  'menu.icons': 'Iconos',
  'menu.input-groups': 'Grupos de Entrada',
  'menu.jumbotron': 'Jumbotron',
  'menu.modal': 'Modal',
  'menu.navigation': 'Navegación',
  'menu.popover-tooltip': 'Tooltips y Popovers',
  'menu.sortable': 'Ordenable',
  'menu.tables': 'Tablas',
  'menu.menu': 'Menú',
  'menu.subhidden': 'Sub Oculto',
  'menu.hidden': 'Oculto',
  'menu.visible': 'Visible',
  'menu.maps': 'Mapas',
  'menu.checkout': 'Pago',
  'menu.landingpage': 'Página de Destino',
  'menu.multipage-home': 'Múltiple Página',
  'menu.singlepage-home': 'Una Página',
  'menu.about': 'Acerca de',
  'menu.auth-login': 'Autenticación',
  'menu.auth-register': 'Registro',
  'menu.blog': 'Blog',
  'menu.blog-detail': 'Detalle del Blog',
  'menu.careers': 'Carrera',
  'menu.confirmation': 'Confirmación',
  'menu.contact': 'Contacto',
  'menu.docs': 'Docs',
  'menu.features': 'Caracteristicas',
  'menu.prices': 'Precios',
  'menu.videos': 'Videos',
  'menu.mailing': 'Correo',
  'menu.invoice': 'Factura',
  'menu.processes': 'Procesos',

  /* 04.Tableros */
  'dashboards.pending-orders': 'Pedidos Pendientes',
  'dashboards.completed-orders': 'Pedidos Completados',
  'dashboards.refund-requests': 'Petición de Reembolso',
  'dashboards.new-comments': 'Nuevos Comentarios',
  'dashboards.sales': 'Ventas',
  'dashboards.orders': 'Pedidos',
  'dashboards.refunds': 'Reembolso',
  'dashboards.recent-orders': 'Pedidos Recientas',
  'dashboards.product-categories': 'Categorías de Producto',
  'dashboards.cakes': 'Tortas',
  'dashboards.tickets': 'Entradas',
  'dashboards.calendar': 'Calendario',
  'dashboards.best-sellers': 'Mejores Vendidos',
  'dashboards.website-visits': 'Visitas al sitio web',
  'dashboards.unique-visitors': 'Visitantes únicos',
  'dashboards.this-week': 'Esta Semana',
  'dashboards.last-week': 'La Semana Pasada',
  'dashboards.this-month': 'Este Mes',
  'dashboards.conversion-rates': 'Medidas de Conversión',
  'dashboards.per-session': 'Por Sección',
  'dashboards.profile-status': 'Estado del Perfil',
  'dashboards.payment-status': 'Estado del Pago',
  'dashboards.work-progress': 'Trabajo en Progreso',
  'dashboards.tasks-completed': 'Tareas Completadas',
  'dashboards.payments-done': 'Pagos Realizados',
  'dashboards.order-stock': 'Pedidos - Valores',
  'dashboards.categories': 'Categorías',
  'dashboards.quick-post': 'Publicación Rápida',
  'dashboards.title': 'Título',
  'dashboards.content': 'Contenido',
  'dashboards.category': 'Categoría',
  'dashboards.save-and-publish': 'Guardar y Publicar',
  'dashboards.top-viewed-posts': 'Publicaciones Más Vistas',
  'dashboards.posts': 'Puestos',
  'dashboards.pending-for-publish': 'Pendiente de Publicación',
  'dashboards.users': 'Usuarios',
  'dashboards.on-approval-process': 'En Proceso de Aprobación',
  'dashboards.alerts': 'Alertas',
  'dashboards.waiting-for-notice': 'Esperando Aviso',
  'dashboards.files': 'Archivos',
  'dashboards.pending-for-print': 'Pendiente para imprimir',
  'dashboards.logs': 'Troncos',
  'dashboards.gogo': 'GOGO',
  'dashboards.magic-is-in-the-details': 'LA MAGIA ESTA EN LOS DETALLES',
  'dashboards.yes-it-is-indeed': '¡Si es verdad!',
  'dashboards.advanced-search': 'Búsqueda Avanzada',
  'dashboards.toppings': 'Coberturas',
  'dashboards.type': 'Categoría',
  'dashboards.keyword': 'Palabra Clave',
  'dashboards.search': 'Búsqueda',
  'dashboards.top-rated-items': 'Artículos Mejores Valorados',

  /* 05.Esquemas */
  'pages.add-new': 'AGREGAR NUEVO',
  'pages.add-new-modal-title': 'Agregar ítem nuevo',
  'pages.display-options': 'Opciones de Pantalla',
  'pages.orderby': 'Ordenar por : ',
  'pages.product-name': 'Nombre del Producto',
  'pages.category': 'Categoría',
  'pages.description': 'Descripción',
  'pages.status': 'Estado',
  'pages.cancel': 'Cancelar',
  'pages.submit': 'Enviar',
  'pages.delete': 'Borrar',
  'pages.another-action': 'Otra accion',
  'pages.actions': 'ACCIONES',
  'pages.header': 'Encabezado',
  'pages.details': 'DETALLES',
  'pages.orders': 'PEDIDOS',
  'pages.rating': 'Clasificación',
  'pages.price': 'Precio',
  'pages.ingredients': 'Ingredients',
  'pages.is-vegan': 'Es Vegano',
  'pages.order-status': 'Estado del Pedido',
  'pages.bake-progress': 'Progreso de Hornear',
  'pages.popularity': 'Popularidad',
  'pages.comments': 'Comentarios',
  'pages.error-title': 'Vaya, parece que ha ocurrido un error!',
  'pages.maintinance-title': '¡En breve estaremos de vuelta!',
  'pages.error-code': 'Código de error',
  'pages.go-back-home': 'REGRESAR A INICIO',
  'pages.mailing-info':
    'Las plantillas de correo utilizan el diseño en línea y el diseño de la tabla para mostrarse bien en varios proveedores de servicios. Para proporcionar una mejor usabilidad, lo incluimos como html regular con dangerouslySetInnerHTML.',
  'pages.invoice-info':
    'La plantilla de factura tiene una versión de estilo en línea para uso fuera del proyecto, así como la versión React.',
  'pages.react-version': 'React Versión',
  'pages.inline-version': 'Estilo en Linea Html Versión',
  'pages.subscribe': 'COMPRAR',
  'pages.dicount-validate': 'Validar',

  /* 06.Aplicaciones */

  /* 06.01.Chatea */
  'chat.messages': 'Mensajes',
  'chat.contacts': 'Contactos',
  'chat.saysomething': 'Di algo..',
  /* 06.02.Encuesta */
  'survey.delete': 'Borrar',
  'survey.edit': 'Edit',
  'survey.add-new': 'AGREGAR NUEVO',
  'survey.add-new-title': 'Add New Encuesta',
  'survey.title': 'Título',
  'survey.category': 'Categoría',
  'survey.label': 'Etiqueta',
  'survey.status': 'Estado',
  'survey.cancel': 'Cancelar',
  'survey.submit': 'Enviar',
  'survey.another-action': 'Otra accion',
  'survey.display-options': 'Opciones de Pantalla',
  'survey.orderby': 'Ordenar por : ',
  'survey.all-surveys': 'Todas las Encuestas',
  'survey.completed-surveys': 'Encuestas Completadas',
  'survey.categories': 'Categorías',
  'survey.active-surveys': 'Encuestas Activas',
  'survey.labels': 'Etiquetas',

  /* 06.04.Notas */
  'todo.add-new': 'AGREGAR NUEVO',
  'todo.add-new-title': 'Añadir Nuevo',
  'todo.title': 'Título',
  'todo.detail': 'Detalle',
  'todo.category': 'Categoría',
  'todo.label': 'Etiqueta',
  'todo.status': 'Estado',
  'todo.cancel': 'Cancelar',
  'todo.submit': 'Enviar',
  'todo.action': 'Accion',
  'todo.another-action': 'Otra accion',
  'todo.display-options': 'Opciones de Pantalla',
  'todo.orderby': 'Ordenar por : ',
  'todo.all-tasks': 'Todas las Tareas',
  'todo.pending-tasks': 'Tareas Pendientes',
  'todo.completed-tasks': 'Tareas Completadas',
  'todo.categories': 'Categorías',
  'todo.labels': 'Etiquetas',

  /* 06.03.Pendientes */
  'task.statement-card-title': 'Sube tu estado de cuenta',
  'task.statement-file-upload': 'Estado de cuenta',
  'task.statement-manual-upload': 'En efectivo',
  'task.statement-nothing': 'Sin ingresos',
  'task.payment-methods-card': 'Pago con tarjeta Crédito/Débito',
  'task.payment-methods-addcard': 'Añadir tarjeta',
  'task.payment-methods-deletecard': 'Eliminar tarjeta',
  'task.payment-methods-paypal': 'Pago con PAYPAL',
  'task.payment-methods-pipeline': 'Linea de Captura',
  'task.payment-methods-codi': 'Pago con codi',
  'task.payment-methods-domiciliacion': 'Pago con Domiciliación',
  'task.payment-methods-referencia': 'Pago con Referencia',
  'task.fiel-tab': 'FIEL',
  'task.ciec-tab': 'RFC',

  /* 06.07.Invoicing */
  'invoicing.tabs.invoicing': 'Control de facturas',
  'invoicing.tabs.contacts': 'Clientes y proveedores',
  'invoicing.tabs.products': 'Productos o servicios',
  'invoicing.reports.pdf-cancel-invoice': 'Cancelar factura',
  'invoicing.tabs.emitted': 'Emitidas',
  'invoicing.tabs.received': 'Recibidas',
  'contacts.tabs.clients': 'Clientes',
  'contacts.tabs.suppliers': 'Proveedores',

  /* 06.07.Configuracion */
  'settings.tabs.business': 'Mi empresa',
  'settings.tabs.plan': 'Mi plan',
  'settings.tabs.credentials': 'Mis credenciales',
  'settings.tabs.payment-methods': 'Métodos de pago',
  'settings.tabs.invoice-data': 'Datos de facturación',
  'settings.tabs.payment-history': 'Historial de pagos',
  'settings.tabs.my-banks': 'Mis bancos',
  'settings.tabs.notifications-center': 'Centro de notificaciones',
  'settings.tabs.brand': 'Imagen de marca',
  'settings.tabs.security': 'Seguridad',
  'settings.plan.title': 'Suscripción',
  'settings.plan.total': 'Total',
  'settings.plan.taxes': 'IVA',
  'settings.plan.subtotal': 'Subtotal',
  'settings.plan.summary': 'Resumen',
  'settings.payment-history.title': 'Historial de pagos',

  /* 06.08.Historial */
  'reports.tabs.reports': 'Reportes',
  'reports.tabs.summary': 'Resumen',
  'reports.resume.title': 'Resumen de impuestos',
  'reports.resume.ingress-total': 'Total',
  'reports.resume.expenses-total': 'Total',

  'reports.resume.ingress': 'Ingresos Acumulados',
  'reports.resume.expenses': 'Gastos Deducibles',

  'reports.resume.title-payroll': 'Impuestos Federales',
  'reports.resume.expenses-iva': 'IVA',
  'reports.resume.expenses-isr': 'ISR',
  'reports.resume.expenses-isr-payroll': 'ISR sobre salarios',

  'reports.resume.title-SS': 'Impuestos de Seguridad Social',
  'reports.resume.SS-AFORE': 'AFORE',
  'reports.resume.SS-IMSS': 'IMSS',
  'reports.resume.SS-INFONAVIT': 'INFONAVIT',

  'reports.resume.title-state': 'Impuestos Estatales',
  'reports.resume.state-taxas-payroll': 'Impuestos sobre la nómina',

  'reports.resume.expenses-total-final': 'Total Impuestos',

  /* 06.09 Documents */
  'documents.tabs.upload': 'Subir documento',
  'documents.tabs.documents': 'Documentos',
  'documents.tabs.status': 'Declaraciones',
  'documents.tabs.fiscalInfo': 'Información fiscal',
  'documents.tabs.legal': 'Documentos fiscales',
  'documents.tabs.declarations': 'Declaraciones  ',
  'documents.tabs.others': 'Otros',

  /* 07.IU */

  /* 07.01.Alertas */
  'alert.rounded': 'Alerta Redondeado',
  'alert.react-notifications': 'React Notificaciones',
  'alert.outline': 'Contorno',
  'alert.primary': 'Primary',
  'alert.secondary': 'Secondary',
  'alert.info': 'Info',
  'alert.success': 'Success',
  'alert.warning': 'Warning',
  'alert.error': 'Error',
  'alert.filled': 'Lleno',
  'alert.primary-text': '¡Esto es un primary alert—check fuera!',
  'alert.secondary-text': '¡Esto es un secondary alert—check fuera!',
  'alert.success-text': '¡Esto es un success alert—check fuera!',
  'alert.danger-text': '¡Esto es un danger alert—check fuera!',
  'alert.warning-text': '¡Esto es un warning alert—check fuera!',
  'alert.info-text': '¡Esto es un info alert—check fuera!',
  'alert.light-text': '¡Esto es un light alert—check fuera!',
  'alert.dark-text': '¡Esto es un dark alert—check fuera!',
  'alert.default': 'Alerta por Defecto',
  'alert.dismissing': 'Despido de Alerta',
  'alert.dismissing-text':
    '¡Santo guacamole! Debes revisar algunos de esos campos a continuación.',
  'alert.dismissing-without-animate-text':
    '¡Estoy alerta y me pueden despedir sin animarme!',

  /* 07.02.Badges */
  'badge.video': 'Ver video',
  'badge.sizes': 'Tamaños',
  'badge.colors': 'Colores',
  'badge.outline': 'Contorno',
  'badge.links': 'Enlaces',
  'badge.counter-badges': 'Mostrador Badges',
  'badge.bootstrap-default': 'Bootstrap Defecto',
  'badge.primary': 'Primary',
  'badge.secondary': 'Secondary',
  'badge.success': 'Success',
  'badge.danger': 'Danger',
  'badge.warning': 'Warning',
  'badge.info': 'Info',
  'badge.light': 'Light',
  'badge.dark': 'Dark',

  /* 07.03.Botones */
  'button.default': 'Bootstrap Defecto',
  'button.colors': 'Colores',
  'button.rounded': 'Botones Redondeado',
  'button.outline': 'Contorno',
  'button.states': 'Estados',
  'button.sizes': 'Tamaños',
  'button.button-groups': 'Grupos de Botones',
  'button.large-button': 'Botón Grande',
  'button.small-button': 'Botón Pequeño',
  'button.extra-small-button': 'Botón Extra Pequeño',
  'button.block-button': 'Botón de Bloqueo',
  'button.active': 'Activo',
  'button.disabled': 'Discapacitado',
  'button.basic': 'Básico',
  'button.toolbar': 'Barra de Herramientas',
  'button.nesting': 'Anidando',
  'button.vertical-variation': 'Variación Vertical',
  'button.checkbox-radio-button': 'Checkbox  y Radio Buttons',
  'button.checkbox': 'Checkbox',
  'button.radio': 'Radio',
  'button.radio-button': 'Radio Button',
  'button.primary': 'Primary',
  'button.secondary': 'Secondary',
  'button.success': 'Success',
  'button.danger': 'Danger',
  'button.warning': 'Warning',
  'button.info': 'Info',
  'button.light': 'Light',
  'button.dark': 'Dark',
  'button.states-text':
    'Este botón muestra una rueda giratoria durante 2 segundos y un icono de error durante 3 segundos antes de cambiar al estado normal. Estos estados se pueden activar agregando y eliminando clases.',
  'button.click-here': 'Haga clic aquí',
  'button.states-text-alternate':
    'Este botón muestra una rueda giratoria durante 2 segundos y un icono de error durante 3 segundos antes de cambiar al estado normal. Estos estados se pueden activar agregando y eliminando clases.',
  'button.primary-link': 'Primary Enlace',
  'button.link': 'Enlace',
  'button.primary-button': 'Primary Botón',
  'button.button': 'Botón',
  'button.left': 'Izquierda',
  'button.middle': 'Centro',
  'button.right': 'Derecha',
  'button.dropdown': 'Desplegable',
  'button.dropdown-link': 'Enlace Desplegable',
  'button.return': 'Regresar',

  /* 07.04.Tarjetas */
  'cards.icon-card': 'Tarjeta de Icono',
  'cards.image-card': 'Tarjeta de Imagen',
  'cards.image-overlay-card': 'Tarjeta de Superposición de Imágenes',
  'cards.image-card-list': 'Lista de Tarjetas de Imagen',
  'cards.tab-card': 'Tarjeta de Tab',
  'cards.user-card': 'Tarjeta de Usuario',

  /* 07.05.Carrusel */
  'carousel.basic': 'Carrusel Básico',
  'carousel.single': 'Carrusel Sólo',
  'carousel.without-controls': 'Carrusel sin Control',

  /* 07.06.Gráficos */
  'charts.line': 'Gráfico de Linea',
  'charts.polar': 'Gráfico de Polar',
  'charts.area': 'Gráfico de Área',
  'charts.scatter': 'Gráfico de Dispersión',
  'charts.bar': 'Gráfico de Barras',
  'charts.radar': 'Gráfico de Radar',
  'charts.pie': 'Gráfico de Circular',
  'charts.doughnut': 'Gráfico de Rosquilla',
  'charts.shadow': 'Sombra',
  'charts.no-shadow': 'Sin Sombra',

  /* 07.07.Colapso */
  'collapse.basic': 'Básico',
  'collapse.toggle': 'Palanca',
  'collapse.accordion': 'Acordeón',
  'collapse.controlled': 'Revisado',
  'collapse.uncontrolled': 'Sin Control',

  /* 07.08.Desplegables */
  'dropdowns.basic': 'Básico',
  'dropdowns.controlled': 'Revisado',
  'dropdowns.uncontrolled': 'Sin Control',
  'dropdowns.dropdown': 'Desplegable',
  'dropdowns.header': 'Encabezado',
  'dropdowns.action': 'Accion',
  'dropdowns.another-action': 'Otra accion',
  'dropdowns.right': 'Derecha',
  'dropdowns.left': 'Izquierda',
  'dropdowns.drop-directions': 'Direcciones de drop',
  'dropdowns.dropright': 'Drop Derecha',
  'dropdowns.dropleft': 'Drop Izquierda',
  'dropdowns.small-button': 'Botón Pequeño',
  'dropdowns.large-button': 'Botón Grande',
  'dropdowns.sizing': 'Dimensionamiento',
  'dropdowns.split-button': 'Botones Desplegables',
  'dropdowns.dropup': 'Dropup',

  /* 07.09.Editores */
  'editors.draftjs': 'Draft.js',
  'editors.quill-standart': 'Quill Estándar',
  'editors.quill-bubble': 'Quill Burbuja',

  /* 07.10.Formularios */
  'forms.basic': 'Básico',
  'forms.email': 'Email',
  'forms.email-muted': 'Nunca compartiremos tu email con nadie más.',
  'forms.password': 'Contraseña',
  'forms.submit': 'Enviar',
  'forms.horizontal': 'Horizontal',
  'forms.radios': 'Radios',
  'forms.first-radio': 'Primero radio',
  'forms.second-radio': 'Segundo radio',
  'forms.third-radio-disabled': 'Tercera radio deshabilitada',
  'forms.checkbox': 'Checkbox',
  'forms.signin': 'Registrarse',
  'forms.top-labels-over-line': 'Etiquetas Superiores Sobre la Línea',
  'forms.tags': 'Etiquetas',
  'forms.date': 'Fecha',
  'forms.state': 'Estado',
  'forms.top-labels-in-input': 'Etiquetas Superiores Sobre la Entrada',
  'forms.email-u': 'EMAIL',
  'forms.password-u': 'CONTRASEÑA',
  'forms.tags-u': 'ETIQUETAS',
  'forms.date-u': 'FECHA',
  'forms.state-u': 'ESTADO',
  'forms.grid': 'Cuadrícula de Formulario',
  'forms.address': 'Dirección',
  'forms.address2': 'Dirección 2',
  'forms.city': 'Ciudad',
  'forms.city-message': 'Por favor seleccione una ciudad!',
  'forms.state-message': 'Por favor seleccione un estado!',
  'forms.zip': 'Código Postal',
  'forms.signup': 'Regístrate',
  'forms.inline': 'Inline',
  'forms.validation': 'Validación',
  'forms.default': 'Defecto',
  'forms.firstname': 'Nombre de Pila',
  'forms.firstname-message': '¡Por favor, introduzca su nombre de pila!',
  'forms.lastname': 'Apellido',
  'forms.lastname-message': '¡Por favor ingrese su apellido!',

  /* 07.11.Componentes */
  'form-components.custom-inputs': 'Entrada Personalizada',
  'form-components.checkboxes': 'Checkboxes',
  'form-components.radios': 'Radios',
  'form-components.inline': 'Inline',
  'form-components.react-select': 'React Select',
  'form-components.state-single': 'Estado Soltero',
  'form-components.state-multiple': 'Estado Múltiple',
  'form-components.react-autosuggest': 'React Autosuggest',
  'form-components.date-picker': 'Selector de Fechas',
  'form-components.date': 'Fecha',
  'form-components.date-range': 'Rango de Fechas',
  'form-components.date-with-time': 'Fecha con Hora',
  'form-components.embedded': 'Incrustado',
  'form-components.dropzone': 'Dropzone',
  'form-components.drop-files-here': 'Soltar Archivos Aquí',
  'form-components.tags': 'Etiquetas',
  'form-components.switch': 'Cambiar',
  'form-components.primary': 'Primary',
  'form-components.secondary': 'Secondary',
  'form-components.primary-inverse': 'Primary Inverso',
  'form-components.secondary-inverse': 'Secondary Inverso',
  'form-components.slider': 'Deslizador',
  'form-components.double-slider': 'Doble Deslizador',
  'form-components.single-slider': 'Único Deslizador',
  'form-components.rating': 'Clasificación',
  'form-components.interactive': 'Interactivo',
  'form-components.readonly': 'Solo Lectura',
  'form-components.type-a-cake': 'Escribe un pastel',
  'form-components.start': 'Comienzo',
  'form-components.end': 'Fin',
  'form-components.tables': 'Tablas',
  /* 07.12.Iconos */
  'icons.simplelineicons': 'Simple Line Iconos',
  'icons.iconsmind': 'Iconsmind Iconos',

  /* 07.13.Grupos de Entrada */
  'input-groups.basic': 'Básico',
  'input-groups.sizing': 'Dimensionamiento',
  'input-groups.small': 'Pequeño',
  'input-groups.default': 'Defecto',
  'input-groups.large': 'Grande',
  'input-groups.checkboxes-and-radios': 'Checkboxes y radios',
  'input-groups.multiple-inputs': 'Entradas Múltiples',
  'input-groups.first-and-last-name': 'Nombre y apellido',
  'input-groups.multiple-addons': 'Complementos Múltiples',
  'input-groups.button-addons': 'Complementos Botón',
  'input-groups.button': 'Botón',
  'input-groups.buttons-with-dropdowns': 'Botones y Desplegables',
  'input-groups.dropdown': 'Desplegable',
  'input-groups.header': 'Encabezado',
  'input-groups.action': 'Accion',
  'input-groups.another-action': 'Otra accion',
  'input-groups.custom-select': 'Personalizada Select',
  'input-groups.options': 'Opciones',
  'input-groups.choose': 'Escoger...',
  'input-groups.custom-file-input': 'Entrada de Archivo Personalizada',

  /* 07.14.Jumbotron */
  'jumbotron.hello-world': '¡Hola Mundo!',
  'jumbotron.lead':
    'Esta es una unidad de héroe simple, un componente de estilo jumbotron simple para llamar la atención extra al contenido o información destacados.',
  'jumbotron.lead-detail':
    'Utiliza clases de utilidad para tipografía y espaciado para espaciar el contenido dentro del contenedor más grande.',
  'jumbotron.learn-more': 'Aprende más',

  /* 07.15.Modal */
  'modal.basic': 'Básico',
  'modal.modal-title': 'Título Modal',
  'modal.launch-demo-modal': 'Lanzar Demo Modal',
  'modal.scrolling-long-content': 'Contenido de Desplazamiento Largo',
  'modal.backdrop': 'Fondo',
  'modal.backdrop-value': 'Valor de Fondo',
  'modal.right-modal': 'Derecha Modal',
  'modal.launch-right-modal': 'Launch Derecha Modal',
  'modal.nested-modal': 'Modal Anidado',
  'modal.size': 'Tamaño',
  'modal.launch-large-modal': 'Lanzar Grande Modal',
  'modal.launch-small-modal': 'Lanzar Pequeño Modal',

  /* 07.16.Navegación */
  'nav.basic': 'Navegación Básico',
  'nav.active': 'Activo',
  'nav.disabled': 'Discapacitado',
  'nav.disabled-link': 'Enlace Discapacitado',
  'nav.link': 'Enlace',
  'nav.longer-link': 'Enlace de navegación más largo',
  'nav.another-link': 'Otro Enlace',
  'nav.right': 'Derecha',
  'nav.dropdown': 'Desplegable',
  'nav.header': 'Encabezado',
  'nav.action': 'Accion',
  'nav.another-action': 'Otra accion',
  'nav.horizontal-alignment': 'Alineación Horizontal',
  'nav.vertical-alignment': 'Alineación Vertical',
  'nav.pills': 'Navegación Píldoras',
  'nav.fill-justify': 'Navegación Llenar y Justify',
  'nav.pills-dropdowns': 'Nav Pills with Dropdowns',
  'nav.pagination-basic': 'Paginación Básico',
  'nav.pagination-sizing': 'Paginación Dimensionamiento',
  'nav.large': 'Grande',
  'nav.small': 'Pequeño',
  'nav.center': 'Centro',
  'nav.pagination-alignment': 'Alineación de Paginación',
  'nav.breadcrumb-basic': 'Migas de Pan Básico',

  /* 07.17.Tooltips y Popovers */
  'popover-tooltip.popover': 'Popovers',
  'popover-tooltip.tooltip': 'Tooltips',

  /* 07.18.Ordenable */
  'sortable.columns': 'Clasificar Columnas',
  'sortable.basic': 'Básico',
  'sortable.handles': 'Handles',

  /* 07.19.Maps */
  'maps.google': 'Google',
  'maps.yandex': 'Yandex',

  /* 7.20 */
  'workflows.tabs.summary': 'Mensuales',
  'workflows.tabs.reports': 'Reportes',
  'workflows.tabs.annuals': 'Anuales',

  /* 7.21 Flows */
  'flow.sales': 'Ingresos por ventas',
  'flow.loan': 'Préstamo',
  'flow.donation': 'Donación',
  'flow.family_income': 'Traspaso entre familia',
  'flow.inheritance': 'Herencia',
  'flow.not_business': 'Ingresos no relacionado con el negocio',
  'flow.payroll': 'Nómina',
  'flow.advance_payment': 'Anticipo de clientes',
  'flow.investment_interest': 'Intereses por inversión',
  'flow.public_sales': 'Ventas al público en general',
};
