/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { AvGroup, AvField } from 'availity-reactstrap-validation';
import { NavLink, useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { logoutUser } from '@redux/actions';
import useSubscriptions from '@hooks/useSubscriptions';
import { useWindowSize } from '@hooks';
import { HelpCenter } from '@containers';
import { HelpCenterSearch, Emoji, CustomModal } from '@components';
import FeedbackSideItem from '@components/FeedbackSideItem';
import { Button, Flex } from '@konta/ui';
import useCreateInvoiceDraft from '@hooks/useCreateInvoiceDraft';
import {
  FilePlus03LineIcon,
  SearchSmLineIcon,
  UsersPlusLineIcon,
} from '@konta/icons';
import { useCommandMenuContext } from '@providers/CommandMenuProvider';
import useFeature from '@hooks/useFeature';
import { COMMAND_MENU } from '@constants/featureFlags';
import SidebarNavMenu from './SidebarNavMenu';
import SidenavItem from './SidenavItem';

function Sidebar({ menuHiddenBreakpoint, taxable_entity, pending_workflows }) {
  const size = useWindowSize();
  const { toggleShowCommandMenu } = useCommandMenuContext();
  const [isCommandMenuEnabled] = useFeature(COMMAND_MENU);
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showHelpCenter, setShowHelpCenter] = useState(false);
  const [openInvitePeople, setOpenInvitePeople] = useState(false);
  const { subscriptions, loading } = useSubscriptions();
  const isMobile = size.width < menuHiddenBreakpoint;

  const { createInvoiceDraft, loadingCreateInvoiceDraft } =
    useCreateInvoiceDraft();
  const toogleMobileMenu = () => {
    if (isMobile) {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    }
  };

  const handleOpenHelpCenter = () => {
    setShowHelpCenter(!showHelpCenter);
  };

  const toggleOpenInvitePeople = () => {
    setOpenInvitePeople(!openInvitePeople);
  };

  const handleSubmitInvite = (event, values, errors) => {
    console.log('values', values);
    console.log('Invite sent');
  };

  const isRejected = taxable_entity?.onboarding_detail?.is_rejected_user;
  return (
    <>
      {isMobileMenuOpen && (
        <div
          className="sidebar-backgorund"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}
      <div
        className={`sidebar-help-center ${
          showHelpCenter ? 'show-help-center' : ''
        } `}
      >
        <PerfectScrollbar
          options={{ suppressScrollX: true, wheelPropagation: false }}
        >
          <CardBody className="pt-3">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="ml-1 mb-0">Centro de Ayuda</h4>
              <div
                className="close-sub-menu mr-2"
                onClick={handleOpenHelpCenter}
              >
                <span aria-hidden="true">×</span>
              </div>
            </div>
            <span className="ml-1 text-muted text-small d-block">
              En esta sección encontrará todo el apoyo que necesita para
              realizar sus impuestos rápida y sencillamente.
            </span>
            <HelpCenterSearch />
            <HelpCenter />
          </CardBody>
        </PerfectScrollbar>
      </div>
      <div
        className={`sidebar-container ${
          isMobileMenuOpen ? 'sidebar-container-mobile-open' : ''
        }`}
      >
        <div className="sidebar-header-container">
          <a className="sidebar-header-logo" href="/">
            <span className="logo d-block" />
          </a>
          <div className="sidebar-header-action-container">
            <Flex gap={6}>
              <Button
                variant="outlined"
                color="gray"
                size="m"
                onClick={createInvoiceDraft}
                loading={loadingCreateInvoiceDraft}
                leftIcon={<FilePlus03LineIcon />}
                noFill
                css={{
                  justifyContent: 'start',
                  width: '100%',
                }}
              >
                Nueva Factura
              </Button>
              {isCommandMenuEnabled && (
                <Button
                  onClick={toggleShowCommandMenu}
                  noFill
                  variant="outlined"
                  icon
                  size="m"
                  color="gray"
                >
                  <SearchSmLineIcon />
                </Button>
              )}
            </Flex>
          </div>
        </div>
        <div className="sidebar-content-container">
          <SidebarNavMenu
            isRejected={isRejected}
            location={location}
            toogleMobileMenu={toogleMobileMenu}
            isMobile
            pending_workflows={pending_workflows}
          />

          <div className="sidebar-content-separator" />
          <div className="sidebar-content-space" />
          <div className="sidebar-content-footer-container">
            <FeedbackSideItem
              taxableEntity={taxable_entity}
              isMobile={isMobile}
            />
            <SidenavItem
              to="/app/referrals"
              onClick={toogleMobileMenu}
              icon={<UsersPlusLineIcon className="sidebar-nav-link-icon" />}
            >
              Refiere amigos
            </SidenavItem>
            {/* {!isRejected && (
              <SidenavItem
                icon={<LifeBuoy01LineIcon />}
                onClick={handleOpenHelpCenter}
              >
                Soporte
              </SidenavItem>
            )} */}
          </div>
        </div>
        {(!loading && subscriptions.length === 0) ||
          (taxable_entity.status === 'demo' && (
            <div className="sidebar-footer-container">
              <div className="sidebar-footer-card">
                <span className="sidebar-header-profile-name">
                  <Emoji symbol="🚀" label="rocket" />
                  <span>Plan Básico</span>
                </span>
                <NavLink exact to="/app/settings/plan">
                  <span className="clickable-text-color">Contratar</span>
                </NavLink>
              </div>
            </div>
          ))}
        {isMobile && (
          <NavLink
            to="#"
            className="sidebar-mobile-button icon-button"
            onClick={toogleMobileMenu}
          >
            <svg width="14" height="10" viewBox="0 0 14 10" fill="#6B6F76">
              <rect width="14" height="2" />
              <rect y="4" width="14" height="2" />
              <rect y="8" width="14" height="2" />
            </svg>
          </NavLink>
        )}
      </div>
      <CustomModal
        title="Invitar persona"
        toggle={toggleOpenInvitePeople}
        isOpen={openInvitePeople}
        handleValidSubmit={handleSubmitInvite}
        submitText="Enviar invitaciones"
        size="md"
      >
        <div className="m-auto text-center">
          <AvGroup>
            <AvField
              type="textarea"
              name="email"
              placeholder="person@example.com"
              validate={{
                required: { value: true, errorMessage: 'Se requiere un email' },
                email: { value: true, errorMessage: 'Email inválido' },
              }}
              required
            />
          </AvGroup>
        </div>
      </CustomModal>
    </>
  );
}

const mapStateToProps = ({ menu, taxableEntity, workflows }) => {
  const { workflows: workflowList } = workflows;
  const pending_workflows = workflowList.filter(
    (item) =>
      item.status === 'pending_payment' || item.status === 'in_proccess',
  );
  const { taxable_entity } = taxableEntity;
  const { menuHiddenBreakpoint } = menu;
  return {
    menuHiddenBreakpoint,
    taxable_entity,
    pending_workflows,
  };
};

export default connect(mapStateToProps, { logoutUser })(Sidebar);
