import { Routes, Route } from 'react-router-dom';

import Header from './Header';
import BlogList from './BlogList/BlogList';

export default function Academy() {
  return (
    <div className="app-container">
      <Header />
      <Routes>
        <Route path="/" element={<BlogList />} />
      </Routes>
    </div>
  );
}
