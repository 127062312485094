import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@konta/ui';
import useInvoiceForm from '@hooks/useInvoiceForm';
import { FormikProvider } from 'formik';
import { useToggle } from 'rooks';
import { Modal, ModalBody } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationManager } from '@components/Notifications';
import useInvoiceTemplates from '@hooks/useInvoiceTemplates';
import InvoiceDetails from './InvoiceDetails';
import InvoicePreview from './InvoicePreview';
import InvoiceConcepts from './InvoiceConcepts';
import InvoiceIssuerInfo from './InvoiceIssuerInfo';
import InvoiceClient from './InvoiceClient';
import NewInvoiceModalHeader from './NewInvoiceModalHeader.tsx';
import InvoiceDetailsModal from './InvoiceDetailsModal';
import ConfirmStampModal from './ConfirmStampModal.tsx';

export default function NewInvoiceModal({ visible, onClose }) {
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showExtraDetails, toggleShowExtraDetails] = useState(false);
  const [showInvoicePreview, toggleShowInvoicePreview] = useToggle(true);
  const [payload, setPayload] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const { invoiceTemplates, refetch } = useInvoiceTemplates({
    params: {
      'q[invoice_template_id_not_null]': 1,
      'q[invoice_template_deleted_at_not_null]': 1,
      per_page: 150,
    },
  });
  const showConfirmStampModal = !!payload;
  // NOTE: invoice this can be in a context to no longer pass as prop between components
  const invoice = useInvoiceForm({
    draftId: params.uuid,
    onSubmitForm: (data) => {
      setPayload(data.payload);
    },
    onSuccess: () => {
      NotificationManager.success(
        'Factura creada con éxito.',
        'Factura creada',
        6000,
      );
      navigate('/app/invoicing', { replace: true });
    },
  });

  const handleSubmit = async () => {
    const hasConceptsWithMoreThan1000Characters =
      invoice.formik.values?.concepts?.some?.(
        (concept) => concept?.product?.value?.description?.length > 1000,
      );
    if (hasConceptsWithMoreThan1000Characters) {
      NotificationManager.error(
        'La descripción de uno o más conceptos excede el límite de 1000 caracteres.',
        'Error',
        6000,
      );
      return;
    }
    const errors = await invoice.formik.validateForm();
    invoice.formik.submitForm();

    const hasErrorsInDetails =
      errors.invoiceType ||
      errors.paymentType ||
      errors.paymentMethod ||
      errors.clientRegime ||
      errors.currency ||
      errors.cfdiUsage ||
      errors.orderNumber ||
      errors.invoiceNumber ||
      errors.createdDate ||
      errors.fiscalRegime ||
      errors.taxResidency ||
      errors.exchangeRate ||
      errors.periodicity ||
      errors.periodicityMonth ||
      errors.periodicityYear ||
      errors.taxIdentityRegistrationNumber ||
      errors.taxResidency;

    if (hasErrorsInDetails) {
      toggleShowDetails();
    }
    const hasErrorsInExtraDetails =
      errors.taxIdentityRegistrationNumber || errors.taxResidency;
    if (hasErrorsInExtraDetails) {
      toggleShowExtraDetails(true);
    }
  };

  const { has_csd, legal_name, fiscal_regimes, postcode } =
    invoice.taxableEntity;

  const hasBillingData =
    has_csd && !!legal_name && !!postcode && fiscal_regimes.length > 0;

  return (
    <FormikProvider value={invoice.formik}>
      <Modal
        isOpen={visible}
        toggle={onClose}
        fullscreen
        className="modal-fullscreen"
      >
        <NewInvoiceModalHeader
          invoice={invoice}
          onClose={onClose}
          showInvoicePreview={showInvoicePreview}
          toggleShowInvoicePreview={toggleShowInvoicePreview}
          onSubmit={handleSubmit}
          invoiceTemplates={invoiceTemplates}
          refetchTemplates={refetch}
        />
        {showConfirmStampModal && (
          <ConfirmStampModal
            isOpen={showConfirmStampModal}
            toggle={() => setPayload(null)}
            payload={payload}
            invoice={invoice}
          />
        )}
        <ModalBody>
          <Flex
            css={{
              flexDirection: 'column',
              '@md': { gap: '5rem', flexDirection: 'row' },
            }}
          >
            <Flex
              direction="column"
              css={{
                '@md': {
                  flex: '1',
                  width: showInvoicePreview ? '50%' : 'auto',
                },
                position: 'relative',
              }}
            >
              <InvoiceIssuerInfo
                invoice={invoice}
                hasBillingData={hasBillingData}
              />
              <InvoiceClient invoice={invoice} />
              <InvoiceConcepts invoice={invoice} />
              <InvoiceDetails
                invoice={invoice}
                onEdit={toggleShowDetails}
                onSubmit={handleSubmit}
                invoiceTemplates={invoiceTemplates}
                refetchTemplates={refetch}
              />
              <InvoiceDetailsModal
                isOpen={showDetails}
                toggle={toggleShowDetails}
                extraDetailsOpen={showExtraDetails}
                toggleExtraDetails={toggleShowExtraDetails}
                invoice={invoice}
              />
            </Flex>
            {showInvoicePreview && (
              <Flex
                css={{
                  flexDirection: 'column',
                  maxWidth: '512px',
                  '@<md': {
                    maxWidth: '100%',
                  },
                }}
              >
                <InvoicePreview
                  invoice={invoice}
                  withSettingsText
                  withEmailHeader
                />
              </Flex>
            )}
          </Flex>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
}

NewInvoiceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
