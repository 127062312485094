import {
  PFAE_SAT_KEY,
  SALARIES_SAT_KEY,
  RESICO_SAT_KEY,
  PF_SAT_KEY,
} from 'shared/constants/grids';
import { AnnualDeclaration, Declaration } from 'types/entities';

function getDeclarationTitle(satKeys: number[]) {
  if (satKeys.length === 0) {
    return 'Sin título';
  }
  if (
    satKeys.length === 2 &&
    satKeys.every((satKey) => [PF_SAT_KEY, PFAE_SAT_KEY].includes(satKey))
  ) {
    return 'PFAE + PLATAFORMAS';
  }
  if (satKeys.includes(PFAE_SAT_KEY)) {
    return 'PFAE';
  }
  if (satKeys.includes(PF_SAT_KEY)) {
    return 'PLATAFORMAS';
  }
  if (satKeys.includes(RESICO_SAT_KEY)) {
    return 'RESICO';
  }
  if (satKeys.includes(SALARIES_SAT_KEY)) {
    return 'SALARIOS';
  }

  return 'Sin título';
}
export default function getDataFromAnnualDeclaration(
  annualDeclaration: AnnualDeclaration,
  declarations: Declaration[],
) {
  const annualDeclarationTotals =
    annualDeclaration?.annual_declaration_totals ?? [];
  const hasSalaries = annualDeclarationTotals?.some(
    (total) => total.source_type === 'salary',
  );
  const hasPfae = annualDeclarationTotals?.some(
    (total) => total.source_type === 'business_activity',
  );
  const hasPlatforms = annualDeclarationTotals?.some(
    (total) => total.source_type === 'digital_platform',
  );
  const isPfaeAndPlatforms = () => {
    const declarationHasPfae = declarations.some(
      (declaration) => declaration.fiscal_regime?.sat_key === PFAE_SAT_KEY,
    );
    const declarationHasPlatforms = declarations.some(
      (declaration) => declaration.fiscal_regime?.sat_key === PF_SAT_KEY,
    );
    return (
      (hasPfae && hasPlatforms) ||
      (declarationHasPfae && declarationHasPlatforms)
    );
  };
  const satKeys = [];
  const hasPfaeAndPlatforms = isPfaeAndPlatforms();
  if (hasPfaeAndPlatforms) {
    satKeys.push(PFAE_SAT_KEY, PF_SAT_KEY);
  }
  if (hasPfae && !hasPfaeAndPlatforms) {
    satKeys.push(PFAE_SAT_KEY);
  }
  if (hasPlatforms && !hasPfaeAndPlatforms) {
    satKeys.push(PF_SAT_KEY);
  }
  if (hasSalaries && satKeys.length === 0) {
    satKeys.push(SALARIES_SAT_KEY);
  }

  return {
    title: getDeclarationTitle(satKeys),
    satKeys,
  };
}
