import { styled } from '@konta/ui';

export const BreadcrumbsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  pb: '22px',
  background: '$white',
  width: '100%',
  borderBottom: '1px solid $gray200',
});

export const ContainerBackButton = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '24px 16px',
  pl: '0',
  alignItems: 'center',
  borderTop: '1px solid $gray200',
  background: '$white',
  position: 'fixed',
  bottom: 0,
  zIndex: 1000,
});
