import { Flex, Section } from '@konta/ui';
import AnnualDeclarationEntries from 'shared/components/AnnualDeclarationEntries';
import NavigationWrapper from 'shared/components/NavigationWrapper';
import useAnnualDeclarations from './useAnnualDeclarations';
import AnnualDeclaration from './AnnualDeclaration/AnnualDeclaration';
import getDataFromAnnualDeclaration from './getDataFromAnnualDeclaration';
import EmptyState from './EmptyState';
import AnnualDeclarationsLoader from './AnnualDeclarationsLoader';

export default function AnnualDeclarations() {
  const {
    onHandleEntriesView,
    workflows,
    period,
    currentAnnualDeclarations,
    declarations,
    preferredFiscalRegime,
    view,
    views,
    workflowsId,
    taxableEntity,
    filters,
    setView,
    entriesBreadcrumbs,
    annualDeclarations,
    setPeriod,
    isLoading,
  } = useAnnualDeclarations();

  if (isLoading) {
    return <AnnualDeclarationsLoader />;
  }

  if (currentAnnualDeclarations.length === 0) {
    return (
      <EmptyState
        period={period}
        annualDeclarations={annualDeclarations}
        setPeriod={setPeriod}
      />
    );
  }

  return (
    <Flex
      className="app-container"
      direction="column"
      style={{ background: '#ffffff' }}
      gap={10}
    >
      {currentAnnualDeclarations.map((currentAnnualDeclaration) => {
        const data = getDataFromAnnualDeclaration(
          currentAnnualDeclaration,
          declarations,
        );
        return (
          <Section
            key={currentAnnualDeclaration.id}
            css={{
              pt: 0,
            }}
            title={`Declaración anual ${data.title} ${period}`}
            subtitle="Esto es el detalle de tus impuestos durante el año"
            content={
              <>
                {view === views.annualDeclaration && (
                  <AnnualDeclaration
                    onHandleEntriesView={onHandleEntriesView}
                    workflows={workflows}
                    period={period}
                    currentAnnualDeclaration={currentAnnualDeclaration}
                    preferredFiscalRegime={preferredFiscalRegime}
                    data={data}
                    annualDeclarations={annualDeclarations}
                    setPeriod={setPeriod}
                  />
                )}
                {view === views.entries && (
                  <NavigationWrapper
                    breadcrumbs={entriesBreadcrumbs}
                    onClickBackBtn={() => setView(views.annualDeclaration)}
                  >
                    <AnnualDeclarationEntries
                      workflowsId={workflowsId}
                      taxableEntity={taxableEntity}
                      filters={filters}
                      period={period}
                    />
                  </NavigationWrapper>
                )}
              </>
            }
          />
        );
      })}
    </Flex>
  );
}
