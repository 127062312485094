import { useMemo } from 'react';
import {
  Alert,
  Dialog,
  DialogContent,
  Flex,
  config,
  useMediaQuery,
} from '@konta/ui';
import { XCloseLineIcon } from '@konta/icons';
import ReactMarkdown from 'react-markdown';
import { MarkdownData } from 'types/blog';
import {
  BlogDialogHeader,
  BlogDialogHeaderContent,
  BlogDialogHeaderClose,
  BlogContent,
  BlogPostTitle,
  BlogPostSummary,
  BlogPostAuthorContainer,
} from './styled';

interface PostDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  blogContent: MarkdownData | null;
  alwaysFullScreen?: boolean;
}
export default function PostDialog({
  isOpen,
  setIsOpen,
  blogContent,
  alwaysFullScreen = false,
}: PostDialogProps) {
  const isTabletOrMobile = useMediaQuery(config.media['<sm']);
  const slideForClose = alwaysFullScreen ? false : isTabletOrMobile;

  const dialogContentProps = useMemo(() => {
    if (slideForClose) {
      return {
        position: 'bottom',
        slideForClose: true,
      } as { position: 'bottom'; slideForClose: true };
    }
    return {
      position: 'center' as const,
    };
  }, [slideForClose]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        size="full"
        css={{ padding: '0px', display: 'flex', flexDirection: 'column' }}
        {...dialogContentProps}
      >
        {!slideForClose && (
          <BlogDialogHeader>
            <BlogDialogHeaderContent>
              <BlogDialogHeaderClose>
                <XCloseLineIcon />
              </BlogDialogHeaderClose>
              <span>{blogContent?.metadata?.title || 'No encontrado'}</span>
            </BlogDialogHeaderContent>
          </BlogDialogHeader>
        )}
        <Flex column align="center" overflow>
          <BlogContent>
            {blogContent ? (
              <>
                <BlogPostTitle>{blogContent.metadata?.title}</BlogPostTitle>
                <BlogPostSummary>
                  {blogContent.metadata?.summary}
                </BlogPostSummary>
                <BlogPostAuthorContainer>
                  <img src={blogContent.metadata?.img} alt="Avatar" />
                  <div data-slot="title">
                    <span data-slot="author">
                      {blogContent.metadata?.author}
                    </span>
                    <span data-slot="description">
                      {blogContent.metadata?.bio}
                    </span>
                  </div>
                </BlogPostAuthorContainer>
                <ReactMarkdown>{blogContent.content}</ReactMarkdown>
              </>
            ) : (
              <Flex column>
                <Alert color="error">Error al cargar el post del blog</Alert>
              </Flex>
            )}
          </BlogContent>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
