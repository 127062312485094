import { MessageCircle02LineIcon } from '@konta/icons';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnnualsDeclarationQuery from '@hooks/useAnnualsDeclarationQuery';
import usePendingTasksStore, { TASK_PRIORITIES } from 'store/pendingTasksStore';

export default function useAnnualDeclarationPreview() {
  const navigate = useNavigate();
  const {
    loadingDeclaration: loadingAnnualDeclarations,
    declarations: annualDeclarations,
  } = useAnnualsDeclarationQuery();
  const { addPendingTask } = usePendingTasksStore();

  const currentAnnualDeclaration = useMemo(
    () =>
      annualDeclarations?.find(
        (declaration) => declaration.period === dayjs().year(),
      ),
    [annualDeclarations],
  );

  const isAnnualDeclarationOpen = useMemo(
    () => currentAnnualDeclaration?.status === 'open',
    [currentAnnualDeclaration],
  );

  useEffect(() => {
    if (!!currentAnnualDeclaration && isAnnualDeclarationOpen) {
      addPendingTask({
        priority: TASK_PRIORITIES.LOW,
        id: `annual-declaration-${currentAnnualDeclaration.id}`,
        type: 'annualDeclarationPreview',
        status: 'requiredAction',
        title: `Consulta el cálculo estimado de tu declaración anual`,
        description:
          'Ya puedes acceder al cálculo preliminar de tus impuestos anuales',
        extraDescription: currentAnnualDeclaration,
        details: [
          {
            label: 'Revisa el cálculo estimado de tu declaración anual',
            icon: <MessageCircle02LineIcon />,
            iconCss: {
              background: '#D1FADF',
              path: {
                fill: 'none',
                stroke: '#039855',
              },
            },
          },
        ],
        handleAction: () => {
          navigate(`/app/declaraciones/anuales`);
        },
      });
    }
  }, [
    isAnnualDeclarationOpen,
    addPendingTask,
    navigate,
    currentAnnualDeclaration,
  ]);

  return {
    loadingAnnualDeclarations,
  };
}
