import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useToggle } from 'rooks';
import { workflowsFetch } from '@redux/actions';
// eslint-disable-next-line import/no-cycle
import { MonthDatePicker } from '@containers';
import Loading from '@components/Utils/Loading';
import { isValidMonthAndYearParams } from '@util';
// eslint-disable-next-line import/no-cycle
import { SelectedMonthViewContainer } from './WorkflowTab';

const getValueAndNumberFromUrlParam = (search) => {
  const query = new URLSearchParams(search);
  return {
    value: isValidMonthAndYearParams(query)
      ? dayjs(`${query.get('year')}-${query.get('month')}-01`).format(
          'YYYY-MM-DD',
        )
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    number: isValidMonthAndYearParams(query)
      ? dayjs(`${query.get('year')}-${query.get('month')}-01`).format('MM')
      : dayjs().startOf('month').format('MM'),
  };
};

function MonthlyWorkflows({ workflows, loading }) {
  const { search } = useLocation();

  const [showOldWorkflow, setShowOldWorkflow] = useToggle(false);

  const [selectedMonth, setSelectedMonth] = useState(
    getValueAndNumberFromUrlParam(search),
  );

  useEffect(() => {
    setSelectedMonth(getValueAndNumberFromUrlParam(search));
  }, [search]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workflowsFetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showOldWorkflow) {
      setShowOldWorkflow();
    }
  }, [selectedMonth]);

  const selectedWorkflow = workflows?.find(
    (item) => item.start_date === selectedMonth.value,
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <MonthDatePicker
        dataWithDatesToMatch={workflows}
        setSelectedMonth={setSelectedMonth}
        selectedMonth={selectedMonth}
      />
      <SelectedMonthViewContainer
        workflows={workflows}
        selectedMonth={selectedMonth}
        showOldWorkflow={showOldWorkflow}
        setShowOldWorkflow={setShowOldWorkflow}
        selectedWorkflow={selectedWorkflow}
      />
    </>
  );
}

const mapStateToProps = ({ workflows, taxableEntity }) => {
  const { taxable_entity } = taxableEntity;
  const SPECIAL_USERS_2024 = [23529];
  const taxableEntityId = +(taxable_entity?.id || 0);

  const NOT_ALLOWER_USERS = [];

  // TODO remove when client is ready to work
  // this client has many arrears but can already work in 2024, except for the previous years.
  if (SPECIAL_USERS_2024.includes(taxableEntityId)) {
    return {
      ...workflows,
      workflows:
        workflows?.workflows?.filter?.(
          (workflow) => dayjs(workflow.start_date).year() === 2024,
        ) || [],
    };
  }

  if (NOT_ALLOWER_USERS.includes(taxableEntityId)) {
    return {
      error: null,
      workflows: [],
      selected_workflow: {},
    };
  }

  return workflows;
};

export default connect(mapStateToProps, {
  workflowsFetch,
})(MonthlyWorkflows);
