import dayjs from 'dayjs';
import _ from 'lodash';
import { Flex, Text, Button, theme } from '@konta/ui';
import { Workflow, WorkflowStatus } from 'types/entities';
import { CornerDownRightLineIcon, ArrowUpRightLineIcon } from '@konta/icons';
import { workflowStatusNames } from 'shared/constants/workflows';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: {
    p: '$20',
    border: '1px solid $gray200',
    borderRadius: '8px',
    backgroundColor: '$gray100',
    gap: '16px',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  button: {
    '--color': '$colors$primary700',
    path: { fill: 'none' },
    '&:hover': {
      '--color': '$colors$primary500',
    },
  },
  itemContainer: {
    alignItems: 'flex-start',
    gap: '6px',
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
};

interface PendingWorkflowsProps {
  pendingWorkflows: Workflow[];
}
export default function PendingWorkflows({
  pendingWorkflows,
}: PendingWorkflowsProps) {
  const navigate = useNavigate();

  const workflowsByStatus = pendingWorkflows.reduce((acc, workflow) => {
    if (!acc[workflow.status]) {
      acc[workflow.status] = [];
    }
    acc[workflow.status].push(workflow);
    return acc;
  }, {} as Record<string, Workflow[]>);

  const formatMonths = (workflows: Workflow[]) =>
    workflows
      .map((w) => _.capitalize(dayjs(w.start_date).format('MMMM')))
      .join(', ');

  const handleNavigateToWorkflow = (workflows: Workflow[]) => {
    const oldestWorkflow = workflows.reduce(
      (oldest, current) =>
        dayjs(current.start_date).isBefore(dayjs(oldest.start_date))
          ? current
          : oldest,
      workflows[0],
    );

    const date = dayjs(oldestWorkflow.start_date);
    const month = date.format('MM');
    const year = date.format('YYYY');

    navigate(`/app/declaraciones?month=${month}&year=${year}`);
  };

  return (
    <Flex css={styles.container}>
      <Flex gap={4} column>
        <Text m color="gray700" bold>
          Meses pendientes
        </Text>
        <Text xs color="gray600">
          Tienes pendiente los meses:
        </Text>
      </Flex>
      <Flex css={styles.itemContainer}>
        {Object.entries(workflowsByStatus).map(([status, workflows]) => (
          <Flex key={status} gap={8} itemsCenter>
            <CornerDownRightLineIcon
              width={14}
              height={14}
              color={theme.colors.gray500.value}
            />
            <Text xs color="gray500">
              {formatMonths(workflows)}:
            </Text>
            <Text xs color="gray700">
              {workflowStatusNames[status as WorkflowStatus]}
            </Text>
            <Button
              size="xs"
              variant="text"
              css={styles.button}
              rightIcon={<ArrowUpRightLineIcon />}
              onClick={() => handleNavigateToWorkflow(workflows)}
            >
              Ir al proceso
            </Button>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
