import type { DeclarationType, WorkflowStatus } from 'types/entities';
import {
  blueDark,
  blueLighter,
  gray,
  grayDark,
  grayLighter,
  green,
  grayLight,
  yellow,
  blueLighter2,
  yellowLight,
  orangeLight,
  violetLight,
  orange,
  blueDark2,
  violet,
  greenLight,
  blueLight,
  blue,
  orange2,
  orangeLight2,
  defaultGray,
  defaultGrayDark,
} from 'shared/util/colors';
import {
  Bell04LineIcon,
  CalculatorLineIcon,
  FileCheck01LineIcon,
  CurrencyDollarCircleLineIcon,
  FileSearch02LineIcon,
  FilePlus01LineIcon,
  Flag01LineIcon,
  BankLineIcon,
} from '@konta/icons';
import type { ComponentType } from 'react';

export const workflowStatusNames: Record<WorkflowStatus, string> = {
  fetching_invoice: 'Obteniendo facturas',
  classifying: 'Clasificando',
  ready: 'Lista para comenzar',
  in_proccess: 'En progreso',
  verify_declaration: 'Verificando declaración',
  submit_declaration: 'Presentando declaración',
  pending_payment: 'Pendiente de pago',
  paid: 'Pagada',
  done: 'Completada',
  bank_payment: 'Pendiente de pago en banco',
};

export const workflowStatusIcons: Record<WorkflowStatus, ComponentType> = {
  fetching_invoice: FileSearch02LineIcon,
  classifying: FileSearch02LineIcon,
  ready: Flag01LineIcon,
  in_proccess: Bell04LineIcon,
  verify_declaration: CalculatorLineIcon,
  submit_declaration: FilePlus01LineIcon,
  pending_payment: CurrencyDollarCircleLineIcon,
  paid: CurrencyDollarCircleLineIcon,
  done: FileCheck01LineIcon,
  bank_payment: BankLineIcon,
};

export const withoutWorkflowStatusChip = [orangeLight2, orange2];
export const withoutWorkflowStatusName = 'Sin workflow';
export const withoutWorkflowStatusKeyName = 'withoutWorkflowStatus';
export const withoutCurrentPhaseKeyChip = {
  color: defaultGray,
  contrastColor: defaultGrayDark,
};
export const withoutCurrentPhaseKeyName = 'Sin fase actual';

export const workflowStatusChip: Record<
  WorkflowStatus,
  [color: string, contrastColor: string]
> = {
  fetching_invoice: [grayLight, gray],
  classifying: [blueLighter, blueDark],
  ready: [grayLighter, grayDark],
  in_proccess: [orangeLight2, orange2],
  verify_declaration: [yellowLight, yellow],
  submit_declaration: [blueLighter2, blueDark2],
  pending_payment: [orangeLight, orange],
  paid: [violetLight, violet],
  done: [greenLight, green],
  bank_payment: [blueLight, blue],
};

export const declarationTypeChip: Record<
  DeclarationType,
  [color: string, contrastColor: string]
> = {
  normal: [grayLight, gray],
  complementary: [blueLighter, blueDark],
};

export const workflowStatusOptions = Object.entries(workflowStatusNames).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
workflowStatusOptions.unshift({
  value: withoutWorkflowStatusKeyName,
  label: withoutCurrentPhaseKeyName,
});
