import api from '@api';
import axios from 'axios';

interface PostFeedbackProps {
  comment: string;
  rating: number;
  evaluatedType: string;
  evaluatedId: number;
}

export default async function postFeedback({
  comment,
  rating,
  evaluatedType,
  evaluatedId,
}: PostFeedbackProps) {
  const { data, status } = await api.post<PostFeedbackProps>(`/feedbacks`, {
    comment,
    rating,
    evaluated_id: evaluatedId || '',
    evaluated_type: evaluatedType || '',
  });

  return { ...data, status };
}
