import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getMonthAndYearDate } from '@util/Utils';
import { FeaturedSearchIcon } from '@konta/icons';
import { Flex, Icon, Text } from '@konta/ui';
import { Button, Card, CardTitle, CardBody, Badge, Tooltip } from 'reactstrap';
import { Table } from '@components';
import { NavLink } from 'react-router-dom';
import { workflowsFetch } from '@redux/actions';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';
import Loader from '@components/Loader';
import dayjs from 'dayjs';
import HelpCenterLink from '@components/HelpCenterLink';

function PlatformWorkflowsCard({ workflows, loading }) {
  const { completedProcesses } = useCurrentProcessesQuery();
  const data = workflows.filter((workflow) => workflow.status !== 'done');

  const availableWorkflow = [...workflows]
    .reverse()
    .find(
      (workflow) =>
        workflow.status === 'ready' ||
        workflow.status === 'pending_payment' ||
        workflow.status === 'in_proccess' ||
        workflow.status === 'submit_declaration' ||
        workflow.status === 'verify_declaration',
    );

  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return <p>Fecha</p>;
        },
        accessor: 'start_date',
        Cell: ({ value }) => {
          const workFlowDate = new Date(value);
          return (
            <p className="list-item-heading">
              Proceso de {getMonthAndYearDate(workFlowDate)}
            </p>
          );
        },
      },
      {
        Header: 'Estatus',
        accessor: 'status',
        Cell: ({ value, row }) => {
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggle = () => {
            setTooltipOpen(!tooltipOpen);
          };
          const color =
            {
              fetching_invoice: 'outline-warning',
              classifying: 'outline-warning',
              not_ready: 'outline-warning',
              in_proccess: 'outline-info',
              verify_declaration: 'outline-warning',
              submit_declaration: 'outline-warning',
              ready: 'outline-primary',
              pending_payment: 'outline-primary',
              paid: 'outline-success',
              done: 'outline-success',
            }[value] || 'outline-light';
          const status =
            {
              fetching_invoice: 'Preparando',
              classifying: 'Preparando',
              not_ready: 'Preparando',
              ready: 'Lista para comenzar',
              pending_payment: 'Pagar declaración',
              in_proccess: 'En progreso',
              verify_declaration: 'Verificando declaración',
              submit_declaration: 'Presentando declaración',
              paid: 'Pagada',
              done: 'Completada',
            }[value] || 'Preparando';
          const statusText =
            {
              fetching_invoice:
                'La declaración se encuentra descargando todas las facturas automáticamente, recibirá un correo cuando esté lista.',
              classifying:
                'La declaración se encuentra descargando todas las facturas automáticamente, recibirá un correo cuando esté lista.',
              not_ready: 'No se encuentra listo el Workflow',
              ready: 'La declaración se encuentra lista para ser comenzada',
              in_proccess: 'La declaración se encuentra en proceso',
              verify_declaration: 'El contador está verificando la declaración',
              submit_declaration: 'Presentando declaración',
              pending_payment: 'Pagar declaración',
              paid: 'La declaración está pagada',
              done: 'La declaración se encuentra completada',
            }[value] || 'Preparando';
          return (
            <>
              <Badge id={`statusInfo-${row.id}`} color={color} className="mb-1">
                {status}
              </Badge>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={`statusInfo-${row.id}`}
                toggle={toggle}
              >
                {statusText} {`  `}
                <p>
                  <HelpCenterLink />
                </p>
              </Tooltip>
            </>
          );
        },
      },
      {
        Header: ' ',
        accessor: ' ',
        Cell: ({ row }) => {
          const status =
            {
              fetching_invoice: 'Preparando',
              classifying: 'Preparando',
              not_ready: 'Preparando',
              ready: 'Comenzar',
              in_proccess: 'Continuar',
              verify_declaration: 'Revisar',
              submit_declaration: 'Revisar',
              pending_payment: 'Pagar declaración',
              paid: 'Procesando pago',
              done: 'Completada',
            }[row.original.status] || 'Preparando';
          // this bypass is for issue by mercadolibre only affect a platforms users
          const hasBeforeMonthBypass = () => {
            // Verificar si no hay declaraciones.
            if (!row.original?.declarations) {
              return false;
            }
            const declaration = row.original?.declarations?.[0];
            // if declaration year is current year pass
            if (
              !!declaration?.start_date &&
              dayjs(declaration?.start_date).year() === dayjs().year()
            ) {
              return true;
            }
            const fiscalRegimeId = declaration?.fiscal_regime_id;
            // User that has before month bypass
            const specialUserIds = [6383];

            if (specialUserIds.includes(row.original.taxable_entity_id)) {
              const declarationMonth =
                dayjs(declaration.start_date).month() + 1;
              const currentMonth = dayjs().month();
              return fiscalRegimeId === 4 && declarationMonth <= currentMonth;
            }

            // Si el ID actual no está en la lista, verifica que la fecha de inicio es del mes anterior.
            const currentMonth = dayjs(declaration.start_date).month() + 1;
            return fiscalRegimeId === 4 && currentMonth === dayjs().month();
          };
          const is_disabled =
            row.original.id !== availableWorkflow?.id &&
            !hasBeforeMonthBypass();
          const [year, month] = row.original.start_date.split('-');
          return (
            <NavLink
              id={`isDisableInfo-${row.id}`}
              color="primary"
              exact
              to={`/app/declaraciones?month=${month}&year=${year}`}
            >
              <Button disabled={is_disabled} color="primary" size="xs">
                {status}
              </Button>
            </NavLink>
          );
        },
      },
    ];
  }, [availableWorkflow]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(workflowsFetch());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  if (workflows.length === 0 && !completedProcesses) {
    return (
      <Flex
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          alignSelf: 'stretch',
          justifyContent: 'center',
          height: '500px',
        }}
      >
        <Icon
          css={{
            width: '48px',
            height: '48px',
            svg: {
              width: 'auto',
              height: 'auto',
              path: {
                fill: 'none',
              },
            },
          }}
        >
          <FeaturedSearchIcon />
        </Icon>
        <Flex column gap={8} align="center" justify="center">
          <Text bold l>
            No hay tareas pendientes
          </Text>
          <Text color="gray500">
            Aquí encontrarás las tareas que requieren tu atención.
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={20}>
      {(workflows.length > 0 || completedProcesses) && (
        <Card className="mb-4">
          <CardBody>
            <CardTitle tag="h4">Declaraciones pendientes</CardTitle>
            <Table
              noDataText="No tienes ninguna declaración pendiente"
              defaultPageSize={5}
              data={data}
              loading={loading}
              columns={columns}
            />
          </CardBody>
        </Card>
      )}
    </Flex>
  );
}

const mapStateToProps = ({ workflows, taxableEntity }) => {
  const { taxable_entity } = taxableEntity;
  const SPECIAL_USERS_2024 = [23529];
  const NOT_ALLOWER_USERS = [];
  const taxableEntityId = +(taxable_entity?.id || 0);

  // TODO remove when client is ready to work
  // this client has many arrears but can already work in 2024, except for the previous years.
  if (SPECIAL_USERS_2024.includes(taxableEntityId)) {
    return {
      ...workflows,
      workflows:
        workflows?.workflows?.filter?.(
          (workflow) => dayjs(workflow.start_date).year() === 2024,
        ) || [],
    };
  }

  if (NOT_ALLOWER_USERS.includes(taxableEntityId)) {
    return {
      error: null,
      workflows: [],
      selected_workflow: {},
    };
  }

  return workflows;
};

export default connect(mapStateToProps)(PlatformWorkflowsCard);
