import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { getBase64FromUrl } from '@util/Utils';
import { AnnualDeclaration, Workflow } from 'types/entities';

interface UseAnnualDeclarationProps {
  annualDeclaration: AnnualDeclaration;
  preferredFiscalRegime?: number | null;
  data: {
    title: string;
    satKeys: number[];
  };
  workflows: Workflow[];
}
export default function useAnnualDeclaration({
  annualDeclaration,
  preferredFiscalRegime,
  data,
  workflows,
}: UseAnnualDeclarationProps) {
  const [downloadAcusesLoading, setDownloadAcusesLoading] = useState(false);

  const hasAcuses =
    annualDeclaration?.status === 'closed' &&
    annualDeclaration?.acuses?.length > 0;

  const pendingWorkflows = useMemo(
    () =>
      workflows
        .filter((workflow) => workflow.status !== 'done')
        .sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date))),
    [workflows],
  );

  const handleDownloadAcuses = async () => {
    try {
      setDownloadAcusesLoading(true);
      await Promise.all(
        annualDeclaration.acuses.map(async (link) => {
          const blobFile = (await getBase64FromUrl(link)) as string;
          const a = document.createElement('a');
          a.href = blobFile;
          a.download = `acuse-${annualDeclaration.period}.pdf`;
          a.click();
          a.remove();
        }),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadAcusesLoading(false);
    }
  };

  const filteredSatKeys = useMemo(
    () =>
      data.satKeys.filter(
        (satKey) => !preferredFiscalRegime || preferredFiscalRegime === satKey,
      ),
    [data.satKeys, preferredFiscalRegime],
  );

  return {
    workflows,
    filteredSatKeys,
    downloadAcusesLoading,
    handleDownloadAcuses,
    hasAcuses,
    pendingWorkflows,
  };
}
