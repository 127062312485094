import { Flex } from '@konta/ui';
import Main from '@components/DesignSystem/Main';
import Section from 'shared/components/Section';
import DeductionDetails from '@components/DeductionDetails';
import useFeature from '@hooks/useFeature';
import {
  ANNUAL_DEDUCTION_LIMITS,
  INSIGHTS_DASHBOARD,
} from '@constants/featureFlags';
import useMockForNotSuscriptionUsers from '@hooks/useMockForNotSuscriptionUsers';
import Loader from '@components/Loader';
import Heatmap from './Heatmap';
import Insights from './Insights';
import { Container, Root } from './styled';
import { InsightsDialogProvider } from './Insights/InsightsDialogContext';
import useInformationDashboard from './useInformationDashboard';
import Header from './Header';
import FisalVisorModalCheckout from './FiscalVisorModalCheckout/FiscalVisorModalCheckout';
import LineSeries from './LineSeries';
import NotCiecModal from './NotCiecModal';
import FeaturedAlert from './FeaturedAlert';

export default function InformationDashboard() {
  const { loadingMock } = useMockForNotSuscriptionUsers();
  const {
    isResico,
    toggleFiscalVisorModalCheckout,
    hasNotActiveSubscription,
    shouldOpenFiscalVisorModalCheckout,
    openFiscalVisorModalCheckout,
    isFiscalVisorEnabled,
    showSyncMessage,
    isActiveRegimes,
  } = useInformationDashboard();

  const [isInsightsEnabled] = useFeature(INSIGHTS_DASHBOARD);
  const [annualDeductionLimits] = useFeature(ANNUAL_DEDUCTION_LIMITS);
  if (loadingMock) return <Loader />;

  return (
    <Root>
      {isFiscalVisorEnabled && hasNotActiveSubscription && (
        <Section
          css={{
            p: '$16',
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: 'white',
          }}
        >
          <FeaturedAlert
            withChatButton
            onButtonAction={() => {
              toggleFiscalVisorModalCheckout();
            }}
            buttonText="Activa aquí (2 meses gratis)"
            description="Estos son datos de prueba. Te regalamos dos meses gratis del Plan Visor Fiscal para que conozcas tu información."
          />
        </Section>
      )}
      {isFiscalVisorEnabled && !hasNotActiveSubscription && showSyncMessage && (
        <Section
          css={{
            p: '$16',
            width: '100%',
          }}
        >
          <FeaturedAlert
            footerText="Si tienes problemas para visualizar tu información puedes escribir anuestro soporte especializado."
            withChatButton
            withLeftIcon
            title="Estamos sincronizando tus credenciales"
            description="Este proceso puede tomar hasta 24 horas para completarse, dependiendo del volumen de datos. Pronto verás tu información reflejada en el visor."
          />
        </Section>
      )}
      {hasNotActiveSubscription && !isFiscalVisorEnabled && <NotCiecModal />}
      <Main
        onClick={() => {
          if (hasNotActiveSubscription) {
            if (isFiscalVisorEnabled) {
              toggleFiscalVisorModalCheckout();
            }
          }
        }}
        data-block-ui={!isFiscalVisorEnabled}
        isDemo={hasNotActiveSubscription}
      >
        {shouldOpenFiscalVisorModalCheckout && (
          <FisalVisorModalCheckout
            open={openFiscalVisorModalCheckout}
            toggleOpen={toggleFiscalVisorModalCheckout}
          />
        )}
        <Container>
          <Header hasNotActiveSubscription={hasNotActiveSubscription} />
          <Section alignStart>
            <Flex
              column
              css={{
                flex: 1,
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: 16,
                overflow: 'auto',
              }}
            >
              {isInsightsEnabled && (
                <Section>
                  <InsightsDialogProvider>
                    <Insights />
                  </InsightsDialogProvider>
                </Section>
              )}
              {annualDeductionLimits && isActiveRegimes && !isResico && (
                <Section>
                  <DeductionDetails />
                </Section>
              )}
              <Section>
                <LineSeries />
              </Section>
            </Flex>
          </Section>
          <Section css={{ mt: '$18' }}>
            <Heatmap />
            <Flex css={{ flex: 1 }} />
          </Section>
        </Container>
      </Main>
    </Root>
  );
}
