import { FileDownload03LineIcon } from '@konta/icons';
import { Flex, Text, Button } from '@konta/ui';

const styles = {
  container: {
    p: '$20',
    border: '1px solid $gray200',
    borderRadius: '8px',
    backgroundColor: '$gray100',
    gap: '16px',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  button: {
    '--color': '$colors$gray700',
    backgroundColor: '$white',
    path: { fill: 'none' },
    '&:hover': {
      '--color': '$colors$gray500',
    },
  },
};

interface DownloadAcuseBtnProps {
  onClick: () => void;
  loading: boolean;
}
export default function DownloadAcuseBtn({
  onClick,
  loading,
}: DownloadAcuseBtnProps) {
  return (
    <Flex css={styles.container}>
      <Flex gap={4} column>
        <Text m color="gray700" bold>
          ¡Acabaste! 🥳
        </Text>
        <Text xs color="gray600">
          Tu acuse esta listo para descargar.
        </Text>
      </Flex>
      <Flex>
        <Button
          loading={loading}
          size="xs"
          css={styles.button}
          leftIcon={<FileDownload03LineIcon />}
          color="gray"
          variant="outlined"
          onClick={onClick}
          aria-label="Descargar acuse"
        >
          Descargar Acuse
        </Button>
      </Flex>
    </Flex>
  );
}
