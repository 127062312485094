import { styled } from '@konta/ui';

export const BlogContainer = styled('div', {
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  borderRadius: '10px',
  border: '1px solid $gray200',
  backgroundColor: '#FFF',
  boxShadow:
    '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
  zIndex: 10,
  cursor: 'pointer',
});
export const BlogHeader = styled('div', {
  display: 'flex',
  padding: '$16 $20',
  alignItems: 'center',
  gap: '$8',
  alignSelf: 'stretch',
  borderBottom: '1px solid $gray200',
  'div[data-slot="image-container"]': {
    position: 'relative',
    width: '32px',
    height: '32px',
  },
  svg: {
    display: 'flex',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    display: 'flex',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '200px',
  },
  'img[data-error="true"]': {
    display: 'none',
  },
  'svg[data-slot="badge"]': {
    position: 'absolute',
    bottom: '-4px',
    right: '-4px',
    width: '16px',
    height: '16px',
  },
  'div[data-slot="title"]': {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  'span[data-slot="author"]': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    color: '$gray900',
  },
  'span[data-slot="description"]': {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
    color: '$gray500',
  },
});
export const BlogContent = styled('div', {
  fontFamily: 'Inter',
  display: 'flex',
  padding: '24px 20px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '$8',
  alignSelf: 'stretch',
  'span[data-slot="title"]': {
    color: '$gray700',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    // if mobile then height should be 40px
    '@media (max-width: 768px)': {
      height: '40px',
    },
  },
  'span[data-slot="subtitle"]': {
    color: '$gray500',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    height: '60px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
  },
});

export const BlogFooter = styled('div', {
  display: 'flex',
  px: '$20',
  pb: '$24',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '12px',
  alignSelf: 'stretch',
  button: {
    width: '100%',
  },
});
