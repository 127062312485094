export default function getIconFromString(
  emoji: string | React.ReactNode,
): React.ReactNode {
  if (typeof emoji === 'string') {
    if (emoji.startsWith('http') || emoji.startsWith('data:')) {
      return <img src={emoji} alt="Icon" />;
    }
    return emoji;
  }
  return emoji;
}
