import { Command } from 'cmdk';
import dayjs from 'dayjs';
import _ from 'lodash';
import { CalendarLineIcon } from '@konta/icons';
import useWorkflows from 'shared/hooks/useWorkflows';
import Loader from 'shared/components/Loader';
import { useCommandMenuContext } from '@providers/CommandMenuProvider';
import Item from '../Item';

export default function DeclarationPage() {
  const { workflows, workflowsLoading } = useWorkflows();
  const { navigateTo } = useCommandMenuContext();
  const navigateToCreateClientSupplier = (date: string) => {
    const [month, year] = dayjs(date).format('MM-YYYY').split('-');
    navigateTo(`/app/declaraciones?month=${month}&year=${year}`);
  };
  return (
    <>
      {workflowsLoading && (
        <Command.Loading>
          <Loader />
        </Command.Loading>
      )}
      {!workflowsLoading &&
        workflows.map((workflow) => {
          const startDate = dayjs(workflow.start_date).format('MMMM YYYY');
          return (
            <Item
              value={`declaracion declaración ${startDate}`}
              onSelect={() =>
                navigateToCreateClientSupplier(workflow.start_date)
              }
            >
              <CalendarLineIcon />
              {_.capitalize(startDate)}
            </Item>
          );
        })}
    </>
  );
}
