import { DefaultCellTypes, Row } from '@silevis/reactgrid';
import dayjs from 'dayjs';
import useSimplifiedRows from 'shared/hooks/grids/useSimplifiedRows';
import { FORMAT_MONTH } from 'shared/constants/grids';
import { NumberWithIconCell } from 'shared/components/TemplateCells/NumberWithIcon';
import type { SimplifiedDataGrid } from 'types/grids';
import type { Workflow } from 'types/entities';

interface UseRowsProps {
  dataSet?: SimplifiedDataGrid;
  selectedWorkflow: Workflow | null;
  workflows: Workflow[];
}

export default function useRows({
  dataSet,
  selectedWorkflow,
  workflows,
}: UseRowsProps): Row<DefaultCellTypes | NumberWithIconCell>[] {
  const selectedMonth = dayjs(selectedWorkflow?.start_date).format(
    FORMAT_MONTH,
  );
  const selectedYear = dayjs(selectedWorkflow?.start_date).year();

  const rows = useSimplifiedRows({
    dataSet,
    selectedMonth,
    selectedYear,
    workflows,
    source: 'client',
  });

  return rows;
}
