import { useCallback, useMemo } from 'react';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import getSimplifiedDataGridBySatKeys from 'shared/util/getSimplifiedDataGridBySatKeys';
import type { Range } from 'types/grids';
import useAnnualDeclarationColumns from './useAnnualDeclarationColumns';
import useAnnualDeclarationRows from './useAnnualDeclarationRows';

interface UseGridOpts {
  satKeys: number[];
  workflows: Workflow[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
  title: string;
}
export default function useGrid({
  satKeys,
  workflows,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
  title,
}: UseGridOpts) {
  const columns = useAnnualDeclarationColumns();
  const dataSet = useMemo(
    () =>
      getSimplifiedDataGridBySatKeys({
        satKey: satKeys,
        workflows,
        isForAnnualDeclaration: true,
      }),
    [satKeys, workflows],
  );

  const rows = useAnnualDeclarationRows({
    dataSet,
    selectedMonth: null,
    title,
    period,
    selectedAnnualDeclaration,
    workflows,
  });

  const onSelectionChanged = useCallback(
    (range: Range[]) => {
      const {
        first: { column, row },
      } = range[0];
      const monthIdx = column.idx - 1;
      const isExpense = row.idx === 2;

      if (monthIdx < 1 || monthIdx > 12 || row.idx < 1 || row.idx > 2) {
        return;
      }
      onHandleEntriesView(`${monthIdx}`, isExpense ? 'expense' : 'income');
    },
    [onHandleEntriesView],
  );

  return { columns, rows, onSelectionChanged };
}
