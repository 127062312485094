import { Fragment } from 'react';
import { ChevronDownCrFr1OutlineIcon } from '@konta/icons';
import {
  DropdownMenuItem,
  Button,
  DropdownMenuTrigger,
  DropdownMenu,
  Flex,
  Text,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from '@konta/ui';
import { AnnualDeclaration } from 'types/entities';

interface PeriodSelectorProps {
  annualDeclarations: AnnualDeclaration[];
  setPeriod: (period: number) => void;
}

export default function PeriodSelector({
  annualDeclarations,
  setPeriod,
}: PeriodSelectorProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="text"
          leftIcon={<ChevronDownCrFr1OutlineIcon />}
          color="primary"
          size="s"
        >
          Cambiar periodo
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {annualDeclarations
          .sort((a, b) => b.period - a.period)
          .map((declaration) => (
            <Fragment key={declaration.id}>
              <DropdownMenuItem
                key={declaration.id}
                onClick={() => {
                  setPeriod(declaration.period);
                }}
              >
                <Flex align="center">
                  <Text css={{ mr: '$16', ml: '$12' }}>
                    Declaración {declaration.period}
                  </Text>
                </Flex>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </Fragment>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
