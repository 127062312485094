import { useQuery } from 'react-query';
import axios from 'axios';
import extractBlogContent from 'shared/util/extractBlogContent';
import { MarkdownData } from 'types/blog';

export default function useFetchPostContent(url: string) {
  const {
    data: postContent = null,
    isLoading,
    isFetching,
    ...rest
  } = useQuery<MarkdownData>({
    queryKey: ['blogPost', url],
    queryFn: async () => {
      const { data } = await axios.get<string>(url);
      return extractBlogContent(data);
    },
    enabled: !!url,
    staleTime: 5 * 60 * 1000, // 5 minutos por defecto
  });

  return { postContent, loadingPostContent: isLoading || isFetching, ...rest };
}
