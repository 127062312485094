import useFetchPostContent from 'shared/hooks/useFetchPostContent';
import PostDialog from 'shared/components/PostDialog';
import { useNavigate } from 'react-router-dom';
import Loader from 'shared/components/Loader';

const DEFAULT_ROUTE = '/app/academy';

interface PostProps {
  url: string;
}
export default function Post({ url }: PostProps) {
  const navigate = useNavigate();
  const { postContent, loadingPostContent } = useFetchPostContent(url);

  const handleToggleDialog = () => {
    navigate(DEFAULT_ROUTE);
  };

  if (loadingPostContent) {
    return <Loader />;
  }

  return (
    <PostDialog
      isOpen
      setIsOpen={handleToggleDialog}
      blogContent={postContent}
      alwaysFullScreen
    />
  );
}
