import Skeleton from 'react-loading-skeleton';
import { Flex, Section } from '@konta/ui';

export default function AnnualDeclarationsLoader() {
  return (
    <Flex
      className="app-container"
      direction="column"
      style={{ background: '#ffffff' }}
      gap={10}
    >
      <Section
        css={{ pt: 0 }}
        title={<Skeleton width={300} />}
        subtitle={<Skeleton width={400} />}
        content={
          <Flex direction="column" gap={20}>
            <Skeleton height={50} />
            <Skeleton height={400} />
          </Flex>
        }
      />
    </Flex>
  );
}
