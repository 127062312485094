import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { pageSegment } from '@util/Segment';
import { IS_DEVELOP, IS_PRODUCTION } from '@constants/enviroments';
import { TrackerContext } from '@providers/TrackerProvider';
import { update as updateIntercom, formatInfo } from '@util/Intercom';

// See more on: https://www.npmjs.com/package/react-gtm-module
const tagManagerArgs = {
  gtmId: 'GTM-P5HWR96',
};

if (IS_PRODUCTION) {
  TagManager.initialize(tagManagerArgs);
}

export default function useTracker() {
  const location = useLocation();
  const { startTracking } = useContext(TrackerContext);
  const user = useSelector(({ authUser }) => authUser.user);
  const taxable_entity = useSelector(
    ({ taxableEntity }) => taxableEntity.taxable_entity,
  );

  function getParameterByName(name) {
    const fixedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${fixedName}=([^&#]*)`);
    const results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  useEffect(() => {
    const hasWatermark = localStorage?.getItem?.('watermark');
    if (hasWatermark) return;

    if ((IS_PRODUCTION || IS_DEVELOP) && !!user) {
      startTracking();
    }
  }, [startTracking, user]);

  useEffect(() => {
    if (IS_PRODUCTION) {
      pageSegment();
    }
    updateIntercom(formatInfo({ user, taxable_entity }));
  }, [location.pathname]);

  useEffect(() => {
    const code = getParameterByName('rc');
    if (code) {
      if (
        localStorage.getItem('rc') == null ||
        localStorage.getItem('rc') === ''
      ) {
        localStorage.setItem('rc', code);
      }
    }
    const invitationToken = getParameterByName('invitationToken');
    if (
      invitationToken &&
      (localStorage.getItem('invitation-token') == null ||
        localStorage.getItem('invitation-token') === '')
    ) {
      localStorage.setItem('invitation-token', invitationToken);
    }
  });
}
