import { useMemo } from 'react';
import { Column } from '@silevis/reactgrid';
import { generateMonthNames } from 'shared/util/grids';

const COL_WIDTH = 135;

export default function useAnnualDeclarationColumns(): Column[] {
  const months = generateMonthNames().map((month) => ({
    columnId: month,
    width: COL_WIDTH,
  }));

  return useMemo(
    () => [
      {
        columnId: 'concepts',
        width: 250,
      },
      {
        columnId: 'accumulated',
        width: 140,
      },
      ...months,
    ],
    [months],
  );
}
