import { Metadata, MarkdownData } from 'types/blog';

export default function extractBlogContent(markdown: string): MarkdownData {
  if (!markdown) return { metadata: null, content: '' };

  const metadataPattern = /---([^]*?)---/;
  const metadataMatch = markdown.match(metadataPattern);
  let metadata: Metadata | null = null;
  let content = '';

  if (metadataMatch) {
    const rawMetadata = metadataMatch[1].trim();

    const metadataObj: { [key: string]: string } = rawMetadata
      .split('\n')
      .reduce((acc, line) => {
        const colonIndex = line.indexOf(':');
        if (colonIndex !== -1) {
          const key = line.slice(0, colonIndex).trim();
          const value = line.slice(colonIndex + 1).trim();
          acc[key] = value;
        }
        return acc;
      }, {} as { [key: string]: string });

    metadata = {
      author: metadataObj.author,
      img: metadataObj.img,
      bio: metadataObj.bio,
      title: metadataObj.title,
      summary: metadataObj.summary,
    };

    content = markdown.split(metadataMatch[0])[1].trim();
  } else {
    content = markdown.trim();
  }

  return { metadata, content };
}
