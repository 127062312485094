import { Flex } from '@konta/ui';
import AnnualDeclarationGrid from 'shared/components/AnnualDeclarationGrid';
import {
  Workflow,
  AnnualDeclaration as AnnualDeclarationType,
} from 'types/entities';
import DownloadAcuseBtn from './DownloadAcuseBtn';
import Legends from './Legends';
import PendingWorkflows from './PendingWorkflows';
import useAnnualDeclaration from './useAnnualDeclaration';

interface AnnualDeclarationProps {
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  workflows: Workflow[];
  period: string | number;
  setPeriod: (period: number) => void;
  currentAnnualDeclaration: AnnualDeclarationType;
  annualDeclarations: AnnualDeclarationType[];
  preferredFiscalRegime?: number | null;
  data: {
    title: string;
    satKeys: number[];
  };
}

export default function AnnualDeclaration({
  onHandleEntriesView,
  workflows,
  period,
  setPeriod,
  currentAnnualDeclaration,
  annualDeclarations,
  preferredFiscalRegime,
  data,
}: AnnualDeclarationProps) {
  const {
    filteredSatKeys,
    downloadAcusesLoading,
    handleDownloadAcuses,
    hasAcuses,
    pendingWorkflows,
  } = useAnnualDeclaration({
    preferredFiscalRegime,
    data,
    annualDeclaration: currentAnnualDeclaration,
    workflows,
  });
  return (
    <Flex direction="column">
      {hasAcuses && (
        <DownloadAcuseBtn
          onClick={handleDownloadAcuses}
          loading={downloadAcusesLoading}
        />
      )}
      {pendingWorkflows.length > 0 && (
        <PendingWorkflows pendingWorkflows={pendingWorkflows} />
      )}
      <Legends annualDeclarations={annualDeclarations} setPeriod={setPeriod} />
      <AnnualDeclarationGrid
        onHandleEntriesView={onHandleEntriesView}
        satKeys={filteredSatKeys}
        workflows={workflows}
        period={period}
        selectedAnnualDeclaration={currentAnnualDeclaration}
        title={data.title}
      />
    </Flex>
  );
}
