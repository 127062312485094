// Definición de las variables de colores
export const green = '#027A48';
export const greenLight = '#ECFDF3';
export const red = '#B42318';
export const redLight = '#FEF3F2';
export const orange = '#B93815';
export const orangeLight = '#FEF6EE';
export const blue = '#3538CD';
export const blueLight = '#EEF4FF';
export const gray = '#363F72';
export const grayLight = '#F8F9FC';
export const violet = '#6941C6';
export const violetLight = '#F9F5FF';
export const grayDark = '#292c3b';
export const grayLighter = '#b3b5be';
export const blueDark = '#175CD3';
export const blueLighter = '#EFF8FF';
export const yellow = '#c09e0b';
export const yellowLight = '#ffffdc';
export const blueDark2 = '#386cce';
export const blueLighter2 = '#EFF8FF';
export const orange2 = '#fc6a00';
export const orangeLight2 = '#fff5ee';
export const defaultGray = '#e7e7e7';
export const defaultGrayDark = '#3b3b3b';

// Arreglo que utiliza las variables definidas
const colors = [
  // invert color and contrastColor
  { color: greenLight, contrastColor: green },
  { color: redLight, contrastColor: red },
  { color: orangeLight, contrastColor: orange },
  { color: blueLight, contrastColor: blue },
  { color: grayLight, contrastColor: gray },
  { color: violetLight, contrastColor: violet },
  { color: grayLighter, contrastColor: grayDark },
  { color: blueLighter, contrastColor: blueDark },
  { color: yellowLight, contrastColor: yellow },
  { color: blueLighter2, contrastColor: blueDark2 },
  { color: orangeLight2, contrastColor: orange2 },
];

const idToColorMap: Record<number | string, typeof colors[0]> = {};

// eslint-disable-next-line import/prefer-default-export
export const getRandomColorsForChip = (
  id?: string | number,
  defaultColors: {
    color: string;
    contrastColor: string;
  } = { color: defaultGray, contrastColor: defaultGrayDark },
) => {
  if (!id) {
    return defaultColors;
  }
  if (idToColorMap[id]) {
    return idToColorMap[id];
  }

  const index = Math.floor(Math.random() * colors.length);
  const selectedColors = colors[index];

  idToColorMap[id] = selectedColors;

  return selectedColors;
};
