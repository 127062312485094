import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PendingDeclarationsFlowStep,
  StepDataType,
  TextStep,
} from 'types/flows';
import { Button } from '@konta/ui';
import Heatmap from '@routes/app/InformationDashboard/Heatmap';
import getIconFromString from 'shared/util/getIconFromString';
import {
  IconContainer,
  TextStepContentContainer,
  Title,
} from '../TextStepContent/styled';
import TextStepContent from '../TextStepContent';

interface PendingDeclarationsStepContentProps {
  pendingDeclarationsFlowStep: PendingDeclarationsFlowStep;
}

export default function PendingDeclarationsStepContent({
  pendingDeclarationsFlowStep,
}: PendingDeclarationsStepContentProps) {
  const { state: pendingDeclarations } = pendingDeclarationsFlowStep;
  const navigate = useNavigate();

  const textStep = useMemo<TextStep>(() => {
    if (pendingDeclarations.length > 0) {
      return {
        id: 0,
        flow_id: 0,
        type: StepDataType.TEXT,
        params: {
          title: 'En tu opinión de cumplimiento tienes meses pendientes.',
          emoji: '✏️',
          body: `Presentar tus declaraciones a tiempo y hacer el pago oportunamente te ayuda a mantenerte al día con el SAT. Así, evitas multas e intereses por pagos atrasados que pueden aumentar rápidamente el monto total de la deuda.\n\nPor tal motivo, te sugerimos la siguiente información 👉🏼`,
        },
      };
    }
    return {
      id: 0,
      flow_id: 0,
      type: StepDataType.TEXT,
      params: {
        title:
          '¡Excelente noticia! Estás al 100% con tu opinión de cumplimiento.',
        emoji: '💯',
        body: 'Puedes estar tranquilo/a. Todo está en orden y al día, sin pendientes ni sorpresas en el SAT. 😊',
      },
    };
  }, [pendingDeclarations]);

  if (pendingDeclarations && pendingDeclarations.length > 0) {
    return (
      <TextStepContentContainer>
        <IconContainer>
          {getIconFromString(textStep.params.emoji)}
        </IconContainer>
        <Title>{textStep.params.title}</Title>

        <Heatmap />
        {pendingDeclarations && pendingDeclarations.length > 0 && (
          <Button
            color="primary600"
            onClick={() => {
              navigate('/app/pendientes');
            }}
            css={{
              width: '100%',
            }}
          >
            Consulta aquí tus ({pendingDeclarations.length}) pendientes
          </Button>
        )}
      </TextStepContentContainer>
    );
  }

  return <TextStepContent step={textStep} />;
}
